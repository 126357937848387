import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    Alert,
} from '@mui/material';
import axios from 'axios';
import { server } from 'server';
import { toast } from 'react-toastify';
import FlexBetween from 'components/FlexBetween';

const BankOverdraft = ({ employeeName, employeeSal, setOpenOverdraft, id }) => {
    const [formData, setFormData] = useState({
        totalAmount: '',
        hasInterest: false,
        monthlyPayment: 0,
        remainingBalance: 0,
        interestAmount: 0
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Calculate derived values
    useEffect(() => {
        if (formData.totalAmount) {
            const monthlyPayment = employeeSal * 0.4; // 40% of salary
            const interestAmount = formData.hasInterest ? formData.totalAmount * 0.02 : 0;
            const remainingBalance = Number(formData.totalAmount) + interestAmount;

            setFormData(prev => ({
                ...prev,
                monthlyPayment,
                remainingBalance,
                interestAmount
            }));
        }
    }, [formData.totalAmount, formData.hasInterest, employeeSal]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const payload = {
                totalAmount: Number(formData.totalAmount),
                hasInterest: formData.hasInterest,
                monthlyPayment: formData.monthlyPayment,
                remainingBalance: formData.remainingBalance,
                interestAmount: formData.interestAmount,
            };

            await axios.post(`${server}/loan/overdraft/${id}`, payload);
            toast.success('Découvert bancaire créé avec succès');
            setOpenOverdraft(false);
        } catch (err) {
            const errorMessage = err.response?.data?.message || 'Une erreur est survenue';
            setError(errorMessage);
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto', p: 2 }}>
            <FlexBetween>
                <Typography variant="h6" gutterBottom>
                    Découvert bancaire - {employeeName}
                </Typography>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setOpenOverdraft(false)}
                >
                    X
                </Button>
            </FlexBetween>
            <br />

            {error && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error}
                </Alert>
            )}
            <br />

            <form onSubmit={handleSubmit}>
                <TextField
                    fullWidth
                    label="Montant du découvert"
                    type="number"
                    value={formData.totalAmount}
                    onChange={(e) => setFormData(prev => ({
                        ...prev,
                        totalAmount: e.target.value
                    }))}
                    required
                    sx={{ mb: 2 }}
                    inputProps={{
                        max: employeeSal * 1.8, // 60% * 3 months
                        min: 0
                    }}
                    helperText={`Maximum autorisé: ${(employeeSal * 1.8).toLocaleString()} FBU`}
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.hasInterest}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                hasInterest: e.target.checked
                            }))}
                        />
                    }
                    label="Accepter les intérêts de 2%"
                    sx={{ mb: 2 }}
                />

                <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Récapitulatif:
                    </Typography>
                    <Typography variant="body2">
                        • Paiement mensuel: {formData.monthlyPayment.toLocaleString()} FBU
                    </Typography>
                    <Typography variant="body2">
                        • Montant total à rembourser: {formData.remainingBalance.toLocaleString()} FBU
                    </Typography>
                    {formData.hasInterest && (
                        <Typography variant="body2">
                            • Intérêts: {formData.interestAmount.toLocaleString()} FBU
                        </Typography>
                    )}
                    <Typography variant="body2">
                        • Durée: 3 mois
                    </Typography>
                </Box>

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={loading || !formData.totalAmount}
                >
                    {loading ? 'Traitement...' : 'Créer le découvert'}
                </Button>
            </form>
        </Box>
    );
};

export default BankOverdraft; 