import { Box, Button, TextField, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import { server } from "../../../src/server";
import Header from "components/Header";
import { toast } from "react-toastify";

const FormLoan = ({ setOpenPopup, employeeName, id, employeeSal }) => {
  const [formData, setFormData] = useState({
    totalAmount: '',
    monthlyPayment: 0,
    totalMonths: 0,
    percentageOfSalary: 0
  });

  // Calculate monthly payment based on percentage
  useEffect(() => {
    if (formData.totalAmount && employeeSal && formData.percentageOfSalary) {
      const monthlyPayment = (employeeSal * formData.percentageOfSalary) / 100;
      const calculatedMonths = Math.ceil(formData.totalAmount / monthlyPayment);

      setFormData(prev => ({
        ...prev,
        monthlyPayment,
        totalMonths: calculatedMonths
      }));
    }
  }, [formData.totalAmount, formData.percentageOfSalary, employeeSal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        totalAmount: Number(formData.totalAmount),
        monthlyPayment: formData.monthlyPayment,
        totalMonths: formData.totalMonths,
        percentageOfSalary: Number(formData.percentageOfSalary)
      };

      await axios.post(`${server}/loan/loans/${id}`, payload, {
        withCredentials: true,
      });

      toast.success("Prêt créé avec succès");
      setTimeout(() => {
        window.location.replace("/crédits");
      }, 1000);
    } catch (err) {
      toast.error(err.response?.data?.message || "Une erreur s'est produite");
    }
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header title="Credite un employee" />
        <IconButton onClick={() => setOpenPopup(false)}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenPopup(false)}
          >
            X
          </Button>
        </IconButton>
      </Box>
      {/* Formik form */}
      <form onSubmit={handleSubmit}>
        <Box display="grid" gap="20px" gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))">
          <TextField
            fullWidth
            variant="filled"
            label="Nom de l'employé"
            value={employeeName}
            disabled
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Montant total"
            value={formData.totalAmount}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              totalAmount: e.target.value
            }))}
            required
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Pourcentage du salaire"
            value={formData.percentageOfSalary}
            onChange={(e) => setFormData(prev => ({
              ...prev,
              percentageOfSalary: e.target.value
            }))}
            required
            helperText="Pourcentage du salaire mensuel à déduire"
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Paiement mensuel"
            value={formData.monthlyPayment

            }
            disabled
          />
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Nombre de mois"
            value={formData.totalMonths}
            disabled
          />
        </Box>
        <Box mt="20px">
          <Button type="submit" variant="contained" color="primary">
            Créer le prêt
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default FormLoan;
