import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "server";
import {
  Box, Card, CardContent, Typography, Grid, Divider, useTheme,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CircularProgress
} from "@mui/material";
import Header from "components/Header";
import { toast } from "react-toastify";
import DownloadIcon from '@mui/icons-material/Download';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { ArrowLeftSharp } from "@mui/icons-material";
import { nif } from "server";
import { rccm } from "server";

const PaymentInfo = () => {
  const theme = useTheme();
  const { id } = useParams();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const tableRef = useRef(null);
  const [employeeData, setEmployeeData] = useState({
    name: '',
    loan: null,
    overdraft: null
  });

  const [dataAvailability, setDataAvailability] = useState({
    payments: false,
    loan: false,
    overdraft: false
  });

  const [employeeInfo, setEmployeeInfo] = useState({
    name: '',
    cni: '',
    phoneNumber: '',
    role: '',
    address: '',
    bank_account: '',
  });

  const [deductionsAvailability, setDeductionsAvailability] = useState({
    loan: false,
    overdraft: false,
    inss: false
  });

  // Enhanced styles
  const styles = {
    card: {
      backgroundColor: '#ffffff',
      border: '1px solid #e0e0e0',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      borderRadius: '8px',
    },
    sectionCard: {
      backgroundColor: '#ffffff',
      border: '1px solid #e0e0e0',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
      borderRadius: '8px',
      padding: '20px',
      marginBottom: '24px',
    },
    tableHeader: {
      backgroundColor: '#f8fafc',
      color: '#334155',
      fontWeight: '600',
      fontSize: '14px',
      padding: '12px 16px',
      borderBottom: '2px solid #e2e8f0'
    },
    tableCell: {
      padding: '12px 16px',
      fontSize: '14px',
      color: '#475569'
    },
    tableRow: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#f8fafc'
      },
      '&:hover': {
        backgroundColor: '#f1f5f9'
      }
    },
    sectionTitle: {
      color: '#1e293b',
      fontSize: '18px',
      fontWeight: '600',
      marginBottom: '16px',
      paddingBottom: '8px',
      borderBottom: '2px solid #e2e8f0'
    },
    summaryBox: {
      backgroundColor: '#f8fafc',
      padding: '16px',
      borderRadius: '8px',
      marginBottom: '24px'
    },
    summaryText: {
      fontSize: '14px',
      color: '#475569',
      marginBottom: '8px'
    },
    summaryValue: {
      fontSize: '16px',
      color: '#1e293b',
      fontWeight: '600'
    }
  };

  const handleDownloadPDF = async () => {
    try {
      // Create PDF document
      const pdf = new jsPDF('p', 'mm', 'a4');

      // First page - Employee info and Payment History
      const firstPageElement = document.getElementById('page1Content');
      const firstCanvas = await html2canvas(firstPageElement, {
        scale: 2,
        useCORS: true,
        logging: true,
        letterRendering: true,
        backgroundColor: '#ffffff',
      });

      // Add first page content
      pdf.addImage(
        firstCanvas.toDataURL('image/png'),
        'PNG',
        15, // x position
        10, // y position
        180, // width
        250 // height - leaving space for footer
      );

      // Add second page for deductions and other details
      pdf.addPage();

      const secondPageElement = document.getElementById('page2Content');
      const secondCanvas = await html2canvas(secondPageElement, {
        scale: 2,
        useCORS: true,
        logging: true,
        letterRendering: true,
        backgroundColor: '#ffffff',
      });

      // Add second page content
      pdf.addImage(
        secondCanvas.toDataURL('image/png'),
        'PNG',
        15, // x position
        10, // y position
        180, // width
        250 // height - leaving space for footer
      );

      pdf.save(`releve_paiement_${employeeInfo.name.replace(/\s+/g, '_')}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Erreur lors de la génération du PDF");
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        const responses = await Promise.allSettled([
          axios.get(`${server}/loan/employee-payments/${id}`),
          axios.get(`${server}/loan/loan/${id}`),
          axios.get(`${server}/loan/overdraft/${id}`),
          axios.get(`${server}/employee/an-employee/${id}`)
        ]);

        const [paymentRes, loanRes, overdraftRes, employeeRes] = responses;

        // Handle payment history
        if (paymentRes.status === 'fulfilled' && paymentRes.value.data.paymentHistory) {
          setPaymentHistory(paymentRes.value.data.paymentHistory);
          setDataAvailability(prev => ({ ...prev, payments: true }));
        }

        // Handle employee data
        if (employeeRes.status === 'fulfilled' && employeeRes.value.data.OneEmployee) {
          const employee = employeeRes.value.data.OneEmployee;
          setEmployeeInfo({
            name: employee.name,
            cni: employee.cni,
            phoneNumber: employee.phoneNumber,
            role: employee.role,
            address: employee.address,
            bank_account: employee.bank_account,
          });
        }

        // Handle loan data
        if (loanRes.status === 'fulfilled' && loanRes.value.data) {
          setEmployeeData(prev => ({
            ...prev,
            loan: loanRes.value.data
          }));
          setDataAvailability(prev => ({ ...prev, loan: true }));
        }

        // Handle overdraft data
        if (overdraftRes.status === 'fulfilled' && overdraftRes.value.data) {
          setEmployeeData(prev => ({
            ...prev,
            overdraft: overdraftRes.value.data
          }));
          setDataAvailability(prev => ({ ...prev, overdraft: true }));
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Erreur lors du chargement des données");
        setLoading(false);
      }
    };

    fetchAllData();
  }, [id]);

  useEffect(() => {
    if (paymentHistory.length > 0) {
      // Check if any payment has each type of deduction
      setDeductionsAvailability({
        loan: paymentHistory.some(payment =>
          payment.deductions?.loan?.amount > 0),
        overdraft: paymentHistory.some(payment =>
          payment.deductions?.bankOverdraft?.amount > 0),
        inss: paymentHistory.some(payment =>
          payment.deductions?.inss?.amount > 0)
      });
    }
  }, [paymentHistory]);

  const renderSummarySection = () => (
    <Box sx={styles.sectionCard}>
      <Typography sx={styles.sectionTitle}>
        Résumé des Paiements
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box sx={styles.summaryBox}>
            <Typography sx={styles.summaryText}>Salaire Brut Total</Typography>
            <Typography sx={styles.summaryValue}>
              {new Intl.NumberFormat('fr-FR').format(
                paymentHistory.reduce((sum, p) => sum + p.salary, 0)
              )} FBU
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={styles.summaryBox}>
            <Typography sx={styles.summaryText}>Total des Déductions</Typography>
            <Typography sx={styles.summaryValue}>
              {new Intl.NumberFormat('fr-FR').format(
                paymentHistory.reduce((sum, p) => {
                  const deductions = p.deductions;
                  return sum +
                    (deductions.loan?.amount || 0) +
                    (deductions.bankOverdraft?.amount || 0) +
                    (deductions.inss?.amount || 0);
                }, 0)
              )} FBU
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={styles.summaryBox}>
            <Typography sx={styles.summaryText}>Net à Payer Total</Typography>
            <Typography sx={styles.summaryValue}>
              {new Intl.NumberFormat('fr-FR').format(
                paymentHistory.reduce((sum, p) => sum + p.payment, 0)
              )} FBU
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderEmployeeInfo = () => (
    <Box sx={styles.sectionCard}>
      <Typography sx={styles.sectionTitle}>
        Information de l'Employé
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ ...styles.summaryText, fontWeight: '600' }}>
              Informations Personnelles
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography sx={styles.summaryText}>Nom:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={styles.tableCell}>{employeeInfo.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={styles.summaryText}>CNI:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={styles.tableCell}>{employeeInfo.cni}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={styles.summaryText}>Téléphone:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={styles.tableCell}>{employeeInfo.phoneNumber}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Typography sx={{ ...styles.summaryText, fontWeight: '600' }}>
              Informations Professionnelles
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography sx={styles.summaryText}>Fonction:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={styles.tableCell}>{employeeInfo.role}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={styles.summaryText}>Adresse:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={styles.tableCell}>{employeeInfo.address}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={styles.summaryText}>Compte bancaire:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography sx={styles.tableCell}>{employeeInfo.bank_account}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  const renderPaymentSection = () => (
    dataAvailability.payments && paymentHistory.length > 0 && (
      <Box sx={styles.sectionCard}>
        <Typography sx={styles.sectionTitle}>
          Historique des Salaires et Déductions
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeader}>Date</TableCell>
                <TableCell sx={styles.tableHeader}>Mois</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Salaire Brut</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Heures Supp.</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Paiement Heures Supp.</TableCell>
                {deductionsAvailability.loan && (
                  <TableCell sx={styles.tableHeader} align="right">Crédit</TableCell>
                )}
                {deductionsAvailability.overdraft && (
                  <TableCell sx={styles.tableHeader} align="right">Découvert</TableCell>
                )}
                {deductionsAvailability.inss && (
                  <TableCell sx={styles.tableHeader} align="right">INSS</TableCell>
                )}
                <TableCell sx={styles.tableHeader} align="right">Total Déductions</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Net à Payer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistory.map((payment, index) => {
                const totalDeductions = (
                  (payment.deductions?.loan?.amount || 0) +
                  (payment.deductions?.bankOverdraft?.amount || 0) +
                  (payment.deductions?.inss?.amount || 0)
                );

                return (
                  <TableRow key={index} sx={styles.tableRow}>
                    <TableCell sx={styles.tableCell}>
                      {new Date(payment.date).toLocaleDateString('fr-FR')}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>{payment.month}</TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {new Intl.NumberFormat('fr-FR').format(payment.salary)} FBU
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {payment.paymentBreakdown?.additionalHours?.hours || 0} h
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {new Intl.NumberFormat('fr-FR').format(payment.paymentBreakdown?.additionalHours?.amount || 0)} FBU
                    </TableCell>
                    {deductionsAvailability.loan && (
                      <TableCell sx={styles.tableCell} align="right">
                        {payment.deductions?.loan?.amount
                          ? `${new Intl.NumberFormat('fr-FR').format(payment.deductions.loan.amount)} FBU`
                          : '-'}
                      </TableCell>
                    )}
                    {deductionsAvailability.overdraft && (
                      <TableCell sx={styles.tableCell} align="right">
                        {payment.deductions?.bankOverdraft?.amount
                          ? `${new Intl.NumberFormat('fr-FR').format(payment.deductions.bankOverdraft.amount)} FBU`
                          : '-'}
                      </TableCell>
                    )}
                    {deductionsAvailability.inss && (
                      <TableCell sx={styles.tableCell} align="right">
                        {payment.deductions?.inss?.amount
                          ? `${new Intl.NumberFormat('fr-FR').format(payment.deductions.inss.amount)} FBU`
                          : '-'}
                      </TableCell>
                    )}
                    <TableCell sx={{
                      ...styles.tableCell,
                      color: '#ef4444',
                      fontWeight: '600'
                    }} align="right">
                      {new Intl.NumberFormat('fr-FR').format(totalDeductions)} FBU
                    </TableCell>
                    <TableCell sx={{
                      ...styles.tableCell,
                      color: '#22c55e',
                      fontWeight: '600'
                    }} align="right">
                      {new Intl.NumberFormat('fr-FR').format(payment.payment)} FBU
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* Summary Row */}
              <TableRow sx={{
                backgroundColor: '#f8fafc',
                borderTop: '2px solid #e2e8f0'
              }}>
                <TableCell
                  colSpan={3}
                  sx={{
                    ...styles.tableCell,
                    fontWeight: '600'
                  }}
                >
                  Total
                </TableCell>
                <TableCell sx={{
                  ...styles.tableCell,
                  fontWeight: '600'
                }} align="right">
                  {paymentHistory.reduce((sum, p) => sum + (p.paymentBreakdown?.additionalHours?.hours || 0), 0)} h
                </TableCell>
                <TableCell sx={{
                  ...styles.tableCell,
                  fontWeight: '600'
                }} align="right">
                  {new Intl.NumberFormat('fr-FR').format(
                    paymentHistory.reduce((sum, p) => sum + (p.paymentBreakdown?.additionalHours?.amount || 0), 0)
                  )} FBU
                </TableCell>
                {deductionsAvailability.loan && (
                  <TableCell sx={{
                    ...styles.tableCell,
                    fontWeight: '600'
                  }} align="right">
                    {new Intl.NumberFormat('fr-FR').format(
                      paymentHistory.reduce((sum, p) =>
                        sum + (p.deductions?.loan?.amount || 0), 0)
                    )} FBU
                  </TableCell>
                )}
                {deductionsAvailability.overdraft && (
                  <TableCell sx={{
                    ...styles.tableCell,
                    fontWeight: '600'
                  }} align="right">
                    {new Intl.NumberFormat('fr-FR').format(
                      paymentHistory.reduce((sum, p) =>
                        sum + (p.deductions?.bankOverdraft?.amount || 0), 0)
                    )} FBU
                  </TableCell>
                )}
                {deductionsAvailability.inss && (
                  <TableCell sx={{
                    ...styles.tableCell,
                    fontWeight: '600'
                  }} align="right">
                    {new Intl.NumberFormat('fr-FR').format(
                      paymentHistory.reduce((sum, p) =>
                        sum + (p.deductions?.inss?.amount || 0), 0)
                    )} FBU
                  </TableCell>
                )}
                <TableCell sx={{
                  ...styles.tableCell,
                  color: '#ef4444',
                  fontWeight: '600'
                }} align="right">
                  {new Intl.NumberFormat('fr-FR').format(
                    paymentHistory.reduce((sum, p) =>
                      sum + (
                        (p.deductions?.loan?.amount || 0) +
                        (p.deductions?.bankOverdraft?.amount || 0) +
                        (p.deductions?.inss?.amount || 0)
                      ), 0)
                  )} FBU
                </TableCell>
                <TableCell sx={{
                  ...styles.tableCell,
                  color: '#22c55e',
                  fontWeight: '600'
                }} align="right">
                  {new Intl.NumberFormat('fr-FR').format(
                    paymentHistory.reduce((sum, p) => sum + p.payment, 0)
                  )} FBU
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  );

  const renderDeductionsSummary = () => {
    if (!deductionsAvailability.loan &&
      !deductionsAvailability.overdraft &&
      !deductionsAvailability.inss) {
      return null;
    }

    return (
      <Box sx={styles.sectionCard}>
        <Typography sx={styles.sectionTitle}>
          Résumé des Déductions
        </Typography>
        <Grid container spacing={3}>
          {deductionsAvailability.loan && (
            <Grid item xs={12} md={4}>
              <Box sx={styles.summaryBox}>
                <Typography sx={styles.summaryText}>Total Crédit</Typography>
                <Typography sx={styles.summaryValue}>
                  {new Intl.NumberFormat('fr-FR').format(
                    paymentHistory.reduce((sum, p) =>
                      sum + (p.deductions?.loan?.amount || 0), 0)
                  )} FBU
                </Typography>
              </Box>
            </Grid>
          )}

          {deductionsAvailability.overdraft && (
            <Grid item xs={12} md={4}>
              <Box sx={styles.summaryBox}>
                <Typography sx={styles.summaryText}>Total Découvert</Typography>
                <Typography sx={styles.summaryValue}>
                  {new Intl.NumberFormat('fr-FR').format(
                    paymentHistory.reduce((sum, p) =>
                      sum + (p.deductions?.bankOverdraft?.amount || 0), 0)
                  )} FBU
                </Typography>
              </Box>
            </Grid>
          )}

          {deductionsAvailability.inss && (
            <Grid item xs={12} md={4}>
              <Box sx={styles.summaryBox}>
                <Typography sx={styles.summaryText}>Total INSS</Typography>
                <Typography sx={styles.summaryValue}>
                  {new Intl.NumberFormat('fr-FR').format(
                    paymentHistory.reduce((sum, p) =>
                      sum + (p.deductions?.inss?.amount || 0), 0)
                  )} FBU
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    );
  };

  const renderLoanSection = () => {
    if (!deductionsAvailability.loan) return null;

    return (
      <Box sx={styles.sectionCard}>
        <Typography sx={styles.sectionTitle}>
          Historique des Crédits
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeader}>Date</TableCell>
                <TableCell sx={styles.tableHeader}>Mois</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Montant Déduit</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Solde Restant</TableCell>
                <TableCell sx={styles.tableHeader} align="center">Mois Restants</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistory
                .filter(payment => payment.deductions?.loan?.amount > 0)
                .map((payment, index) => (
                  <TableRow key={index} sx={styles.tableRow}>
                    <TableCell sx={styles.tableCell}>
                      {new Date(payment.date).toLocaleDateString('fr-FR')}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>{payment.month}</TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {new Intl.NumberFormat('fr-FR').format(payment.deductions.loan.amount)} FBU
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {new Intl.NumberFormat('fr-FR').format(payment.deductions.loan.remainingBalance)} FBU
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="center">
                      {payment.deductions.loan.monthsLeft}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderOverdraftSection = () => {
    if (!deductionsAvailability.overdraft) return null;

    return (
      <Box sx={styles.sectionCard}>
        <Typography sx={styles.sectionTitle}>
          Historique des Découverts Bancaires
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeader}>Date</TableCell>
                <TableCell sx={styles.tableHeader}>Mois</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Montant Déduit</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Solde Restant</TableCell>
                <TableCell sx={styles.tableHeader} align="center">Mois Restants</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Intérêts</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistory
                .filter(payment => payment.deductions?.bankOverdraft?.amount > 0)
                .map((payment, index) => (
                  <TableRow key={index} sx={styles.tableRow}>
                    <TableCell sx={styles.tableCell}>
                      {new Date(payment.date).toLocaleDateString('fr-FR')}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>{payment.month}</TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {new Intl.NumberFormat('fr-FR').format(payment.deductions.bankOverdraft.amount)} FBU
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {new Intl.NumberFormat('fr-FR').format(payment.deductions.bankOverdraft.remainingAmount)} FBU
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="center">
                      {payment.deductions.bankOverdraft.monthsLeft}
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {payment.deductions.bankOverdraft.interest > 0
                        ? `${new Intl.NumberFormat('fr-FR').format(payment.deductions.bankOverdraft.interest)} FBU`
                        : '-'}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderInssSection = () => {
    if (!deductionsAvailability.inss) return null;

    return (
      <Box sx={styles.sectionCard}>
        <Typography sx={styles.sectionTitle}>
          Déductions INSS
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styles.tableHeader}>Date</TableCell>
                <TableCell sx={styles.tableHeader}>Mois</TableCell>
                <TableCell sx={styles.tableHeader} align="right">Montant Déduit</TableCell>
                <TableCell sx={styles.tableHeader} align="center">Pourcentage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentHistory
                .filter(payment => payment.deductions?.inss?.amount > 0)
                .map((payment, index) => (
                  <TableRow key={index} sx={styles.tableRow}>
                    <TableCell sx={styles.tableCell}>
                      {new Date(payment.date).toLocaleDateString('fr-FR')}
                    </TableCell>
                    <TableCell sx={styles.tableCell}>{payment.month}</TableCell>
                    <TableCell sx={styles.tableCell} align="right">
                      {new Intl.NumberFormat('fr-FR').format(payment.deductions.inss.amount)} FBU
                    </TableCell>
                    <TableCell sx={styles.tableCell} align="center">
                      {payment.deductions.inss.percentage}%
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  const renderCompanyHeader = () => (
    <Box sx={{
      mb: 4,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'start',
      backgroundColor: '#ffffff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
    }}>
      <Box sx={{ width: '150px', height: 'auto' }}>
        <img
          src={Logo}
          alt="Bismos Logo"
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain'
          }}
        />
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="h6" sx={{
          color: '#1e293b',
          fontWeight: '600',
          marginBottom: '4px'
        }}>
          BISMOS
        </Typography>
        <Typography sx={{
          color: '#475569',
          fontSize: '0.875rem',
          marginBottom: '2px'
        }}>
          NIF: {nif}
        </Typography>
        <Typography sx={{
          color: '#475569',
          fontSize: '0.875rem',
          marginBottom: '2px'
        }}>
          RC: {rccm}
        </Typography>
        <Typography sx={{
          color: '#475569',
          fontSize: '0.875rem',
          marginBottom: '2px'
        }}>
          Avenue KIGARAMA, N0: 21
        </Typography>
        <Typography sx={{
          color: '#475569',
          fontSize: '0.875rem'
        }}>
          Centre Fiscal: Ressort Nord
        </Typography>
      </Box>
    </Box>
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Box m="20px">
        <Link to="/employés">
          <Button
            color="secondary"
            variant="contained"
            startIcon={<ArrowLeftSharp />}
            sx={{
              mb: 3,
              backgroundColor: '#475569',
              '&:hover': { backgroundColor: '#334155' }
            }}
          >
            Retour
          </Button>
        </Link>

        {/* Page 1 Content */}
        <div id="page1Content">
          {/* Company Header */}
          {renderCompanyHeader()}

          {renderEmployeeInfo()}
          {renderSummarySection()}
          {renderPaymentSection()}
        </div>

        {/* Page 2 Content */}
        {(deductionsAvailability.loan ||
          deductionsAvailability.overdraft ||
          deductionsAvailability.inss) && (
            <div id="page2Content">
              {/* Repeat company header for page 2 */}
              {renderCompanyHeader()}

              {renderDeductionsSummary()}
              {renderLoanSection()}
              {renderOverdraftSection()}
              {renderInssSection()}
            </div>
          )}

        {/* Download Button */}
        <Box display="flex" justifyContent="flex-end" gap={2} mt={4}>
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleDownloadPDF}
            sx={{
              backgroundColor: '#0ea5e9',
              '&:hover': { backgroundColor: '#0284c7' },
              padding: '10px 24px',
              borderRadius: '8px',
              textTransform: 'none',
              fontSize: '16px'
            }}
          >
            Télécharger PDF Complet
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PaymentInfo;
