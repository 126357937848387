import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from "@mui/material";
import PopupPdf from "./PopupPdf";
import FlexBetween from './FlexBetween'; // Assuming FlexBetween is defined

const PopupPreview = ({ title, openPdf, setOpenPdf, pdfDoc, pdf, handleConfirmPayment }) => {
  // Step 1: Add state for controlling PopupPdf visibility
  const [openConfirmPayment, setOpenConfirmPayment] = useState(false);

  // Step 2: Handler for showing the PopupPdf when "Effectue le payment" is clicked
  const handlePaymentClick = () => {
    setOpenConfirmPayment(true); // Show the confirm payment popup
  };

  const handleCloseConfirmPayment = () => {
    setOpenConfirmPayment(false); // Close the confirm payment popup
  };

  return (
    <>
      <Dialog open={openPdf} onClose={() => setOpenPdf(false)} maxWidth="md" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {pdfDoc ? (
            <iframe
              src={pdfDoc}
              width="100%"
              height="600px"
              title="PDF Preview"
              style={{ border: "none" }}
            ></iframe>
          ) : (
            <p>Loading PDF...</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPdf(false)} variant="contained" color="secondary">Close</Button>
          <Button onClick={() => { handlePaymentClick(); setOpenPdf(false); }} variant="contained" color="secondary">Effectue le payment</Button>
        </DialogActions>
      </Dialog>

      {/* Step 3: PopupPdf component for confirming payment */}
      <PopupPdf openPdf={openConfirmPayment} setOpenPdf={setOpenConfirmPayment}>
        {pdf ? (
          <div>
            {/* Display the close button and the payment confirmation content */}
            <FlexBetween>
              <p>Voulez-vous effectuer le Paiement et télécharger le PDF de Paiement</p>
            </FlexBetween>
            <FlexBetween>
              <Button
                color="secondary"
                variant="contained"
                style={{ marginBottom: "4px" }}
                onClick={() => {
                  handleConfirmPayment();
                  handleCloseConfirmPayment();
                }}
              >
                Valider le Paiement
              </Button>
              <IconButton onClick={handleCloseConfirmPayment}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleCloseConfirmPayment}
                >
                  Ne pas Valider
                </Button>
              </IconButton>
            </FlexBetween>
          </div>
        ) : (
          <p>No PDF generated yet.</p>
        )}
      </PopupPdf>
    </>
  );
};

export default PopupPreview;
