import { Box, Button, TextField, IconButton, Checkbox, FormControlLabel } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { server } from "../../../src/server";
import Header from "components/Header";
import { toast } from "react-toastify";
import "./formemployee.css";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const FormCreateUser = ({ setOpenPopup }) => {
  const [name, setName] = useState("");
  const [cni, setCni] = useState("");
  const [role, setRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [bank_account, setBank_account] = useState(""); // For handling file upload
  const [salary, setSalary] = useState();
  const [avatar, setAvatar] = useState(null);
  const [contractual, setContractual] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (
      !name ||
      !cni ||
      !role ||
      !phoneNumber ||
      !address ||
      !bank_account ||
      !salary
    ) {
      toast.error("Veuillez remplir tous les champs et le fichier.");
      return;
    }
    try {
      const formData = new FormData();

      formData.append("avatar", avatar);
      formData.append("name", name);
      formData.append("cni", cni);
      formData.append("role", role);
      formData.append("phoneNumber", phoneNumber);
      formData.append("address", address);
      formData.append("bank_account", bank_account);
      formData.append("salary", salary);
      formData.append("contractual", contractual);
      const res = await axios.post(
        `${server}/employee/create-employee`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        { withCredentials: true }
      );

      toast.success("Employe creé avec success");
      setTimeout(() => {
        window.location.reload(true);
      }, 300);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };
  const handleFileInputChange = (e) => {
    const newfile = e.target.files[0];
    setAvatar(newfile);
  };

  return (
    <Box m="20px">
      {/* Header */}
      <Box display="flex" justifyContent="space-between">
        <Header title="Creer un employee" />
        <IconButton onClick={() => setOpenPopup(false)}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setOpenPopup(false)}
          >
            X
          </Button>
        </IconButton>
      </Box>
      {/* Formik form */}
      <form onSubmit={handleSubmit}>
        <Box
          display="grid"
          gap="20px"
          gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Nom et Prenom"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="CNI"
            value={cni}
            onChange={(e) => setCni(e.target.value)}
          />
          <TextField
            type="text"
            fullWidth
            variant="filled"
            label="Role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          ></TextField>
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Contact"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="adresse"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="compte bancaire"
            value={bank_account}
            onChange={(e) => setBank_account(e.target.value)}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="salaire"
            value={salary}
            onChange={(e) => setSalary(e.target.value)}
          />
          {/* New field for contractual status */}
          <FormControlLabel
            control={<Checkbox checked={contractual} onChange={(e) => setContractual(e.target.checked)} />}
            label="Est contractuel"
          />
          <div>
            <label htmlFor="avatar" className="ava_tar"></label>
            <div className="div1">
              <span className="span1">
                {avatar ? (
                  <img
                    src={URL.createObjectURL(avatar)}
                    alt="file"
                    className="img_1"
                  />
                ) : (
                  <AddAPhotoIcon style={{ height: "2rem;", width: "2rem;" }} />
                )}
              </span>
              <label htmlFor="file-input" className="label1">
                <span>Upload a file</span>
                <input
                  type="file"
                  name="file"
                  id="file-input"
                  accept=".jpg,.jpeg,.png"
                  classNmae="input_1"
                  onChange={handleFileInputChange}
                />
              </label>
            </div>
          </div>
        </Box>
        <Box mt="20px">
          <Button type="submit" variant="contained" color="primary">
            Créer l'Employee
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default FormCreateUser;
