import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ArrowLeftSharp } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "server";

const HolidayRecords = () => {
  const { id } = useParams(); // Get the employee ID from the route
  const [holidayRecords, setHolidayRecords] = useState([]);
  const [employeeName, setEmployeeName] = useState(""); // State to hold employee name
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Add function to check if holiday is active
  const isHolidayActive = useCallback((record) => {
    const today = new Date();
    const startDate = new Date(record.startDate);
    const endDate = new Date(record.endDate);
    return today >= startDate && today <= endDate;
  }, []);

  // Modify the fetch function to include status updates
  const fetchHolidayRecords = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${server}/employee/holiday/${id}`);
      setHolidayRecords(response.data.holidayRecords);
      setEmployeeName(response.data.nom);
      setLoading(false);
    } catch (error) {
      toast.error("Failed to load holiday records");
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchHolidayRecords();
    // Set up an interval to check statuses periodically
    const intervalId = setInterval(fetchHolidayRecords, 60000); // Check every minute
    return () => clearInterval(intervalId);
  }, [fetchHolidayRecords]);

  const handleEditClick = (record) => {
    setSelectedRecord(record);
    setStartDate(new Date(record.startDate).toISOString().slice(0, 10));
    setEndDate(new Date(record.endDate).toISOString().slice(0, 10));
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setSelectedRecord(null);
  };

  const handleEditSubmit = async () => {
    if (!selectedRecord) return;

    try {
      await axios.put(
        `${server}/employee/api/employee/${id}/edit-holiday/${selectedRecord._id}`,
        { startDate, endDate }
      );
      toast.success("Holiday record updated successfully");
      setEditDialogOpen(false);
      // Reload holiday records
      const response = await axios.get(`${server}/employee/holiday/${id}`);
      setHolidayRecords(response.data.holidayRecords);
    } catch (error) {
      toast.error("Failed to update holiday record");
    }
  };

  // Modify the TableCell for status to show more detailed status
  const getStatusDisplay = (record) => {
    const today = new Date();
    const startDate = new Date(record.startDate);
    const endDate = new Date(record.endDate);

    // Set hours to 0 for accurate date comparison
    today.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    if (record.status === 'completed' || today > endDate) {
      return {
        label: "Terminé",
        color: "green"
      };
    }
    if (record.status === 'active' || (today >= startDate && today <= endDate)) {
      return {
        label: "En cours",
        color: "#2196f3"
      };
    }
    return {
      label: "En attente",
      color: "orange"
    };
  };

  return (
    <Container>
      <Link to="/conge">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "16px" }}
        >
          <ArrowLeftSharp /> Retour
        </Button>
      </Link>

      <Typography variant="h4" gutterBottom>
        Records de conges pour {employeeName}
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : holidayRecords.length === 0 ? (
        <Typography>Aucun enregistrement de conges trouvé.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date de début</TableCell>
                <TableCell>Date de fin</TableCell>
                <TableCell>Congés</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {holidayRecords.map((record) => (
                <TableRow key={record._id}>
                  <TableCell>
                    {new Date(record.startDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {new Date(record.endDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{record.businessDays}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        backgroundColor: getStatusDisplay(record).color,
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        display: "inline-block",
                      }}
                    >
                      {getStatusDisplay(record).label}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleEditClick(record)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>Modifier l'enregistrement des conges</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            margin="dense"
          />
          <TextField
            label="End Date"
            type="date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default HolidayRecords;
