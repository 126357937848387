import { ArrowLeftSharp } from "@mui/icons-material";
import { Box, Button, InputAdornment, TextField, Toolbar, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Header from "components/Header";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "server";
import SearchIcon from "@mui/icons-material/Search";

const Conge = () => {
  const theme = useTheme();
  const [employee, setEmployee] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // Fetch data from the API endpoint
    axios
      .get(`${server}/employee/api/employee`)
      .then((res) => {
        setEmployee(res.data.holidays); // Assuming the response structure contains the employee data directly
      })
      .catch((err) => {
        console.error("Error fetching employee data:", err);
      });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${server}/employee/delete-holiday/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const columns = [

    {
      field: "name",
      headerName: "Nom et Prenom",
      flex: 1,
    },
    {
      field: "totalHolidaysTaken",
      headerName: "les Conges Donneés",
      flex: 1,
    },
    {
      field: "remainingHolidays",
      headerName: "les congés restants",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Fait le ",
      flex: 1,
    },
    {
      field: "view",
      headerName: "Voir",
      width: 150,
      renderCell: (params) => (
        <Link to={"/HolidayRecords/" + params.row.id}>
          <Button
            color="primary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            Voir
          </Button>
        </Link>
      ),
    },
    {
      field: "edit",
      headerName: "Edite",
      width: 100,
      renderCell: (params) => (
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
          onClick={() => handleDelete(params.row.holiday_id)}
        >
          Supprimer
        </Button>
      ),
    },
  ];

  const rows = employee.map((item) => ({
    id: item.employeeId,
    name: item.name,
    totalHolidaysTaken: item.totalHolidaysTaken + " jours",
    remainingHolidays: item.remainingHolidays + " jours",
    createdAt: item.createdAt.slice(0, 10),
    holiday_id: item._id,
  }));

  return (
    <Box m="20px">
      <Link to={"/employés"}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <Header
        title="Les congés"
        subtitle="Liste des employés qui ont eu des conges"
      />
      <TextField
        variant="standard"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Rechercher un produit..."
        sx={{
          width: "300px",
          mb: 2,
          "& .MuiInputBase-root": {
            color: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary[500],
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: theme.palette.secondary[100] }} />
            </InputAdornment>
          ),
        }}
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Conge;
