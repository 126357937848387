import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import Header from 'components/Header';
import { server } from 'server';

const FormHoliday = ({ setOpen, employeeName, id }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [message, setMessage] = useState('');
  const name = employeeName;

  const handleRequestSubmit = async (e) => {
    e.preventDefault();

    // Calculate number of days between startDate and endDate
    const start = new Date(startDate);
    const end = new Date(endDate);
    const daysRequested = calculateBusinessDays(start, end);

    // Check if daysRequested exceeds 20 days
    if (daysRequested > 20) {
      setMessage('La demande de congés dépasse la limite de 20 jours');
      return;
    }

    try {
      const response = await axios.post(`${server}/employee/api/employee/${id}/request-holiday`, {
        startDate,
        endDate,
      });

      setMessage(response.data.message);
      setStartDate('');
      setEndDate('');
      setTimeout(() => {
        window.location.replace("/conge");
      }, 1000);
    } catch (error) {
      setMessage('Error requesting holiday');
      console.error('Error:', error);
    }
  };

  // Function to calculate business days between two dates (excluding weekends)
  const calculateBusinessDays = (startDate, endDate) => {
    let currentDate = new Date(startDate);
    let businessDays = 0;

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // Count only weekdays (Monday to Friday)
        businessDays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return businessDays;
  };

  return (
    <Box m={3}>
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Request Holiday" />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpen(false)}
        >
          X
        </Button>
      </Box>

      {/* Form */}
      <Box mt={3}>
        <form onSubmit={handleRequestSubmit}>
          <Box display="grid" gap={2} gridTemplateColumns="1fr">
            {/* Employee Name */}
            <TextField
              label="Employee Name"
              type="text"
              value={name}
              disabled
              fullWidth
              variant="outlined"
            />

            {/* Start Date */}
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* End Date */}
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              variant="outlined"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* Submit Button */}
            <Box mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit Request
              </Button>
            </Box>
          </Box>
        </form>
      </Box>

      {/* Message */}
      {message && (
        <Typography variant="body1" color={message.includes('Error') ? 'error' : 'success'} mt={2}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default FormHoliday;
