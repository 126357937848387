import React, { useEffect, useState } from "react";
import { Box, Button, InputAdornment, TextField, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";

import { AddBox, ArrowLeftSharp, RemoveRedEyeOutlined } from "@mui/icons-material";
import axios from "axios";
import { server } from "server";
import { Link } from "react-router-dom";
import DataGridRetraitToolbarDetails from "components/DataGridRetraitToolbarDetails";
import SearchIcon from "@mui/icons-material/Search"

const HistoryRetrait = () => {
  const theme = useTheme();
  const [Sales, setSales] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    axios.get(`${server}/retrait/get-all-Retrait`).then((res) => {
      setSales(res.data.allSales);
    });
  }, []);

  const columns = [
    {
      field: "createdAt",
      headerName: "Paiement du:",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Effectué par",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "desc",
      headerName: "Motif",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/retraitDetails/" + params.row.id}>
              <RemoveRedEyeOutlined />
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  Sales &&
    Sales.forEach((item) => {
      row.push({
        id: item._id,
        username: item.username,
        desc: item.desc,
        createdAt: item.createdAt.slice(0, 16),
      });
    });

  return (
    <Box m="1.5rem 2.5rem">
      <Link to={"/stocks"}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <Header
        title="Historiques des retraits en stock"
        subtitle="Liste des Produits retires dans le stock"
      />
      <TextField
        variant="standard"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Rechercher un produit..."
        sx={{
          width: "300px",
          mb: 2,
          "& .MuiInputBase-root": {
            color: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary[500],
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: theme.palette.secondary[100] }} />
            </InputAdornment>
          ),
        }}
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
    </Box>
  );
};

export default HistoryRetrait;
