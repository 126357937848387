import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  Email,
  PointOfSale,
  PersonAdd,
  Traffic,
  MonetizationOn,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  CircularProgress,
  Button,
  Paper,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import BreakdownChart from "components/BreakdownChart";
import StatBox from "components/StatBox";
import axios from "axios";
import { server } from "server";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { CheckBox } from "@mui/icons-material";
import ChangePasswordDialog from 'components/ChangePasswordDialog';
import {
  AccountBalance,
  Payments,
  TrendingUp,
  ShoppingCart,
  Assessment,
  Warning,
  Receipt,
} from "@mui/icons-material";
import { nif } from "server";
import { rccm } from "server";

const Dashboard = () => {
  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [prestations, setPrestations] = useState([]);
  const [prestations1, setPrestations1] = useState([]);
  const [reportData, setReportData] = useState(null);

  const [dailySales, setDailySales] = useState(0);
  const [monthlySales, setMonthlySales] = useState(0);
  const [dailyDepenses, setDailyDepenses] = useState(0);
  const [yearlySales, setYearlySales] = useState(0);
  const [dailyCash, setDailyCash] = useState(0);
  const [dailyBanks, setDailyBanks] = useState(0);
  const [monthlyCash, setMonthlyCash] = useState(0);
  const [monthlyBanks, setMonthlyBanks] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [pendingProducts, setPendingProducts] = useState([]);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //pending Product
        const res = await axios.get(`${server}/product/pendingProducts`);
        setPendingProducts(res.data);
        // Fetch daily cash
        const dailyCash = await axios.get(
          `${server}/payment-commande/daily-paid-amount-cash`
        );
        setDailyCash(dailyCash.data.netCashAmount);

        // Fetch daily banks
        const dailyBanks = await axios.get(
          `${server}/payment-commande/daily-paid-amount-others`
        );
        setDailyBanks(dailyBanks.data.totalPaidAmount);

        // Fetch monthly cash
        const monthlyCash = await axios.get(
          `${server}/payment-commande/monthly-paid-amount-cash`
        );
        setMonthlyCash(monthlyCash.data.totalPaidAmount);

        // Fetch monthly banks
        const monthlyBanks = await axios.get(
          `${server}/payment-commande/monthly-paid-amount-others`
        );
        setMonthlyBanks(monthlyBanks.data.totalPaidAmount);

        // Fetch daily sales
        const dailyResponse = await axios.get(
          `${server}/commande/api/commande/daily`
        );
        setDailySales(dailyResponse.data.total);

        const dailyResponseDepense = await axios.get(
          `${server}/commande/api/commande-depense/daily`
        );
        setDailyDepenses(dailyResponseDepense.data.total);

        // Fetch monthly sales
        const monthlyResponse = await axios.get(
          `${server}/commande/api/commande/monthly`
        );
        setMonthlySales(monthlyResponse.data.total);

        // Fetch yearly sales
        const yearlyResponse = await axios.get(
          `${server}/commande/api/commande/yearly`
        );
        setYearlySales(yearlyResponse.data.total);
      } catch (err) {
        console.error(err);
        // Handle errors appropriately (e.g., display an error message)
      }
    };

    fetchData();
  }, []);

  //feching daily commandes

  useEffect(() => {
    // Fetch data from backend when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/commande/all-commandes`);
        const prestationsArray = response.data || []; // Access prestations array directly or use empty array if not available
        setPrestations(prestationsArray);
        // console.log(prestationsArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchCommandesByDate = async (date) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/commande/commandes-by-date/${date}`
      );
      setPrestations(response.data.commandes);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponible");
      setError("Ces données ne sont pas disponible");
      setLoading(false);
    }
  };

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    fetchCommandesByDate(date);
  };

  const handleDownloadPDF = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/commande/generate-pdf/${selectedDate}`,
        { responseType: "blob" } // Important to handle binary data
      );
      const commandes = response.data.commandes;

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport des Commandes journaliers de ${selectedDate}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Monthly Sales Report Title
      doc.setFontSize(18);
      doc.text("Rapport des commandes mensuelles", 50, 60);

      // Current Date
      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      // Sales Details
      let detailsY = 80;
      commandes.forEach((commande, index) => {
        if (detailsY + 120 > doc.internal.pageSize.height) {
          doc.addPage();
          detailsY = 40;
        }
        doc.setFontSize(14);
        doc.text(`commande ${index + 1}:`, 50, detailsY);
        doc.setFontSize(12);
        doc.text(`Nom Client: ${commande.nomClient}`, 70, detailsY + 10);
        doc.text(`Contact: ${commande.contact}`, 70, detailsY + 20);
        doc.text(`Fait par: ${commande.userName}`, 70, detailsY + 30);
        doc.text(
          `Created At: ${new Date(commande.createdAt).toLocaleDateString()}`,
          70,
          detailsY + 40
        );
        doc.text(`Prestations:`);

        // Products
        doc.setFontSize(12);
        doc.text("Commandes:", 50, detailsY + 40);
        detailsY += 50;

        commande.prestations.forEach((prestation, productIndex) => {
          if (detailsY + 80 > doc.internal.pageSize.height) {
            doc.addPage();
            detailsY = 40;
          }
          doc.text(`  - Commande: ${prestation.commande}`, 60, detailsY);
          doc.text(`    Quantity: ${prestation.qty}`, 80, detailsY + 10);
          doc.text(
            `    Price Unitaire: ${prestation.price_unitaire}`,
            80,
            detailsY + 20
          );
          doc.text(
            `    Price Total: ${prestation.price_total}`,
            80,
            detailsY + 30
          );
          doc.text(`    Avance: ${prestation.avance}`, 80, detailsY + 40);
          doc.text(`    Reste: ${prestation.reste}`, 80, detailsY + 50);
          doc.text(`    Description: ${prestation.desc}`, 80, detailsY + 60);
          doc.text(
            `    Created At: ${prestation.createdAt}`,
            80,
            detailsY + 70
          );
          detailsY += 50;
        });
      });

      // Total Summary
      doc.setFontSize(12);
      const totalPrice = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, commande) => acc + commande.price_total,
            0
          )
        );
      }, 0);

      const totalAvance = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, commande) => acc + commande.avance,
            0
          )
        );
      }, 0);
      const totalReste = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, commande) => acc + commande.reste,
            0
          )
        );
      }, 0);

      doc.text(
        `Total Avance: ${totalAvance} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      doc.text(
        `Total Reste: ${totalReste} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 20,
        {
          align: "right",
          fontWeight: "bold",
        }
      );
      doc.text(
        `Total  Price: ${totalPrice} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 30,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      // Finalize the PDF and trigger download
      doc.save(`Commandes_journaliers_de_${selectedDate}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoading(false);
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandes"
      );
      // setError("Failed to generate PDF. Please try again.");
      setLoading(false);
    }
  };

  //FETCHING MONTHLY COMMANDES
  const [selectedDate1, setSelectedDate1] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading1, setLoading1] = useState(false);
  const [error1, setError1] = useState("");
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    fetchCommandesByMonth(year, month);
  }, []);

  const fetchCommandesByMonth = async (year, month) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/commande/commandes-by-month/${year}/${month}`
      );
      setPrestations1(response.data.commandes);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponible");
      setError("Ces données ne sont pas disponible");
      setLoading(false);
    }
  };

  const handleDateChange1 = (event) => {
    const date = event.target.value;
    setSelectedDate1(date);
    const [year, month] = date.split("-");
    fetchCommandesByMonth(year, month);
  };

  const handleDownloadPDF1 = async () => {
    const [year, month] = selectedDate1.split("-");
    try {
      setLoading1(true);
      const response = await axios.get(
        `${server}/commande/generate-pdf-month/${year}/${month}`
      );
      const commandes = response.data.commandes;

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport des Commandes du mois de ${month}/${year}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif},RC:${rccm}`, 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Monthly Sales Report Title
      doc.setFontSize(18);
      doc.text("Rapport des commandes mensuelles", 50, 60);

      // Current Date
      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      // Add page header and footer
      const addHeaderFooter = (pageNumber) => {
        doc.setFontSize(10);
        doc.text(`Rapport des Commandes du mois de ${month}/${year}`, 10, 10);
        doc.text(
          `Page ${pageNumber}`,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 10
        );
      };

      // Sales Details
      let detailsY = 80;
      let pageNumber = 1;
      addHeaderFooter(pageNumber);

      commandes.forEach((commande, index) => {
        if (detailsY + 120 > doc.internal.pageSize.height) {
          doc.addPage();
          detailsY = 40;
          pageNumber++;
          addHeaderFooter(pageNumber);
        }

        doc.setFontSize(14);
        doc.text(`Commande ${index + 1}:`, 50, detailsY);
        doc.setFontSize(12);
        doc.text(`Nom Client: ${commande.nomClient}`, 70, detailsY + 10);
        doc.text(`Contact: ${commande.contact}`, 70, detailsY + 20);
        doc.text(`Fait par: ${commande.userName}`, 70, detailsY + 30);
        doc.text(`Created At: ${commande.createdAt}`, 70, detailsY + 40);
        doc.text(`Prestations:`, 70, detailsY + 50);

        // Products
        doc.setFontSize(12);
        detailsY += 60;

        commande.prestations.forEach((prestation, productIndex) => {
          if (detailsY + 80 > doc.internal.pageSize.height) {
            doc.addPage();
            detailsY = 40;
            pageNumber++;
            addHeaderFooter(pageNumber);
          }
          doc.text(`  - Commande: ${prestation.commande}`, 60, detailsY);
          doc.text(`    Quantity: ${prestation.qty}`, 80, detailsY + 10);
          doc.text(
            `    Price Unitaire: ${prestation.price_unitaire}`,
            80,
            detailsY + 20
          );
          doc.text(
            `    Price Total: ${prestation.price_total}`,
            80,
            detailsY + 30
          );
          doc.text(`    Avance: ${prestation.avance}`, 80, detailsY + 40);
          doc.text(`    Reste: ${prestation.reste}`, 80, detailsY + 50);
          doc.text(`    Description: ${prestation.desc}`, 80, detailsY + 60);
          doc.text(
            `    Created At: ${prestation.createdAt}`,
            80,
            detailsY + 70
          );
          detailsY += 80;
        });
      });

      // Total Summary
      doc.setFontSize(12);
      const totalPrice = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, prestation) => acc + prestation.price_total,
            0
          )
        );
      }, 0);

      const totalAvance = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, prestation) => acc + prestation.avance,
            0
          )
        );
      }, 0);

      const totalReste = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, prestation) => acc + prestation.reste,
            0
          )
        );
      }, 0);

      doc.text(
        `Total Avance: ${totalAvance} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 30,
        { align: "right" }
      );
      doc.text(
        `Total Reste: ${totalReste} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 20,
        { align: "right" }
      );
      doc.text(
        `Total Price: ${totalPrice} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        { align: "right" }
      );

      // Finalize the PDF and trigger download
      doc.save(`Commandes_du_mois_de_${month}_${year}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoading1(false);
    } catch (error1) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      console.error(error1);
      setLoading1(false);
    }
  };

  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );

  const [loadingYearly, setLoadingYearly] = useState(false);
  const [loadingYear, setLoadingYear] = useState(false);

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    // You can add fetchCommandesByYear(year) here if needed.
  };

  const handleDownloadPDFYear = async () => {
    try {
      setLoadingYear(true);
      const response = await axios.get(
        `${server}/commande/generate-pdf-year/${selectedYear}`,
        { responseType: "blob" }
      );
      const commandes = response.data.commandes;

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport des Commandes de ${selectedYear}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif},RC:${rccm}`, 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Monthly Sales Report Title
      doc.setFontSize(18);
      doc.text("Monthly Sales Report", 50, 60);

      // Current Date
      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      // Sales Details
      let detailsY = 80;
      commandes.forEach((commande, index) => {
        if (detailsY + 120 > doc.internal.pageSize.height) {
          doc.addPage();
          detailsY = 40;
        }
        doc.setFontSize(14);
        doc.text(`commande ${index + 1}:`, 50, detailsY);
        doc.setFontSize(12);
        doc.text(`Nom Client: ${commande.nomClient}`, 70, detailsY + 10);
        doc.text(`Contact: ${commande.contact}`, 70, detailsY + 20);
        doc.text(`Fait par: ${commande.userName}`, 70, detailsY + 30);
        doc.text(
          `Created At: ${new Date(commande.createdAt).toLocaleDateString()}`,
          70,
          detailsY + 40
        );
        doc.text(`Prestations:`);

        // Products
        doc.setFontSize(12);
        doc.text("Commandes:", 50, detailsY + 40);
        detailsY += 50;

        commande.prestations.forEach((prestation, productIndex) => {
          if (detailsY + 80 > doc.internal.pageSize.height) {
            doc.addPage();
            detailsY = 40;
          }
          doc.text(`  - Commande: ${prestation.commande}`, 60, detailsY);
          doc.text(`    Quantity: ${prestation.qty}`, 80, detailsY + 10);
          doc.text(
            `    Price Unitaire: ${prestation.price_unitaire}`,
            80,
            detailsY + 20
          );
          doc.text(
            `    Price Total: ${prestation.price_total}`,
            80,
            detailsY + 30
          );
          doc.text(`    Avance: ${prestation.avance}`, 80, detailsY + 40);
          doc.text(`    Reste: ${prestation.reste}`, 80, detailsY + 50);
          doc.text(`    Description: ${prestation.desc}`, 80, detailsY + 60);
          doc.text(
            `    Created At: ${prestation.createdAt}`,
            80,
            detailsY + 70
          );
          detailsY += 50;
        });
      });

      // Total Summary
      doc.setFontSize(12);
      const totalPrice = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, commande) => acc + commande.price_total,
            0
          )
        );
      }, 0);

      const totalAvance = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, commande) => acc + commande.avance,
            0
          )
        );
      }, 0);
      const totalReste = commandes.reduce((acc, commande) => {
        return (
          acc +
          commande.prestations.reduce(
            (acc, commande) => acc + commande.reste,
            0
          )
        );
      }, 0);

      doc.text(
        `Total Avance: ${totalAvance} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      doc.text(
        `Total Reste: ${totalReste} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 20,
        {
          align: "right",
          fontWeight: "bold",
        }
      );
      doc.text(
        `Total  Price: ${totalPrice} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 30,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      // Finalize the PDF and trigger download
      doc.save(`commande_ de_${selectedYear}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingYear(false);
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      setLoadingYear(false);
    }
  };

  const [selectedYear2, setSelectedYear2] = useState(new Date().getFullYear());
  const [selectedMonth2, setSelectedMonth2] = useState(
    new Date().toISOString().slice(0, 7)
  );
  const [loading2, setLoading2] = useState(false);

  const handleYearChange2 = (event) => {
    setSelectedYear2(event.target.value);
  };

  const handleMonthChange2 = (event) => {
    setSelectedMonth2(event.target.value);
  };

  //GETTING MONTHLY INTEREST
  const handleDownloadPDF2 = async () => {
    const [year, month] = selectedMonth2.split("-");
    try {
      setLoading2(true);
      const response = await axios.get(
        `${server}/commande/generate-monthly-interest-report/${year}/${month}`
      );

      const blob = response.data.commandePDF;
      const doc = new jsPDF();

      // Set document properties (optional)
      doc.setProperties({
        title: `Les Interet sur les commandes du mois de ${month} / ${year}`,
        subject: "les Details des interet",
        author: "Bismos",
      });

      // Company Logo (if applicable)
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      const businessY = logoImgData ? 43 : 20; // Adjust Y position based on logo presence
      doc.setFontSize(12);
      doc.text("Bismos High Tech", 10, businessY);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif},RC:${rccm}`, 10, businessY + 5);
      doc.text(
        "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
        10,
        businessY + 10
      );

      // Invoice Details
      const invoiceY = businessY + 20;
      doc.setFontSize(16);
      doc.text("les Details :", 15, invoiceY);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY
      );

      // Extract data from the array and display it in the PDF
      const detailsY = invoiceY + 20;
      doc.setFontSize(12);
      blob.forEach((data, index) => {
        const yOffset = detailsY + index * 30;
        // doc.text(`Les interet du mois de: ${month}/${year}`, 10, yOffset);
        doc.text(
          `Total Depenses: ${data.totalDepenses.toFixed(2)} fbu`,
          10,
          yOffset + 10
        );
        doc.text(
          `Total Payments: ${data.totalPayments.toFixed(2)} fbu`,
          10,
          yOffset + 20
        );
        doc.text(
          `Total Retraits: ${data.totalRetraits.toFixed(2)} fbu`,
          10,
          yOffset + 30
        );
        doc.text(
          `Total Interest: ${data.totalInterest.toFixed(2)} fbu`,
          10,
          yOffset + 40,
          {
            underline: true,
          }
        );
      });

      // Save the PDF
      doc.save(`Les_Interet_sur_les_commandes_du_mois_de_${month}_${year}.pdf`);

      setLoading2(false);
      toast.success("Le rapport a été téléchargé avec succès");
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      setLoading2(false);
    }
  };

  //GETTING YEARLY INTEREST
  const [selectedYear3, setSelectedYear3] = useState(
    new Date().getFullYear().toString()
  );
  const [loading3, setLoading3] = useState(false);
  const [reportData3, setReportData3] = useState(null);

  const handleYearChange3 = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleDownloadPDF3 = async () => {
    try {
      setLoading3(true);
      const response = await axios.get(
        `${server}/commande/generate-yearly-interest-report/${selectedYear3}`
      );

      const blob = response.data.commandePDF;
      const doc = new jsPDF();

      // Set document properties (optional)
      doc.setProperties({
        title: `Les Interet sur les commandes annuelles de ${selectedYear3}`,
        subject: "les Details des interet",
        author: "Bismos",
      });

      // Company Logo (if applicable)
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      const businessY = logoImgData ? 43 : 20; // Adjust Y position based on logo presence
      doc.setFontSize(12);
      doc.text("Bismos High Tech", 10, businessY);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 10, businessY + 5);
      doc.text(
        "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
        10,
        businessY + 10
      );

      // Invoice Details
      const invoiceY = businessY + 20;
      doc.setFontSize(16);
      doc.text("les Details :", 15, invoiceY);

      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.getWidth() - 60,
        invoiceY
      );

      // Extract data from the array and display it in the PDF
      const detailsY = invoiceY + 20;
      doc.setFontSize(12);
      blob.forEach((data, index) => {
        const yOffset = detailsY + index * 30;
        // doc.text(`Les interet du mois de: ${month}/${year}`, 10, yOffset);
        doc.text(
          `Total Depenses: ${data.totalDepenses.toFixed(2)} fbu`,
          10,
          yOffset + 10
        );
        doc.text(
          `Total Payments: ${data.totalPayments.toFixed(2)} fbu`,
          10,
          yOffset + 20
        );
        doc.text(
          `Total Retraits: ${data.totalRetraits.toFixed(2)} fbu`,
          10,
          yOffset + 30
        );
        doc.text(
          `Total Interest: ${data.totalInterest.toFixed(2)} fbu`,
          10,
          yOffset + 40,
          {
            underline: true,
          }
        );
      });

      // Save the PDF
      doc.save(
        `Les_Interet_sur_les_commandes_annuelles de_${selectedYear3}.pdf`
      );

      setLoading3(false);
      toast.success("Le rapport a été téléchargé avec succès");
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      setLoading3(false);
    }
  };

  const [selectedDateDailyCash, setSelectedDateDailyCash] = useState("");
  const [loadingDailyCash, setLoadingDailyCash] = useState(false);

  const handleDateChangeDailyCash = (event) => {
    const date = event.target.value;
    setSelectedDateDailyCash(date);
  };

  const handleDownloadPDFDailyCash = async () => {
    try {
      setLoading(true);

      const date = new Date(selectedDateDailyCash);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure 2-digit format
      const day = date.getDate().toString().padStart(2, "0");

      const response = await axios.get(
        `${server}/payment-commande/daily-paid-amount-cash/${year}/${month}/${day}`,
        { responseType: "json" }
      );

      const { transactions, totalPaidAmount } = response.data;

      if (!transactions || transactions.length === 0) {
        setLoading(false);
        toast.error("Aucune donnée disponible pour générer le rapport.");
        return;
      }

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport des paiements en cash du ${selectedDateDailyCash}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Daily Sales Report Title
      doc.setFontSize(18);
      doc.text("Rapport des paiements en cash", 50, 60);

      // Current Date
      doc.setFontSize(10);
      doc.text(
        `Date: ${new Date().toLocaleDateString()}`,
        doc.internal.pageSize.width - 50,
        20
      );

      // Sales Details
      let startY = 80;
      let transactionIndex = 0;
      let totalPages = 1;

      const addPageAndHeader = () => {
        doc.addPage();
        // Add Company Logo and Header on each new page
        if (logoImgData) {
          doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
        }
        doc.setFontSize(14);
        doc.text("Bismos High Tech", 50, 20);
        doc.setFontSize(10);
        doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 50, 30);
        doc.text(
          "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
          50,
          40
        );
        doc.setFontSize(18);
        doc.text("Rapport des paiements en cash", 50, 60);
        doc.setFontSize(10);
        doc.text(
          `Date: ${new Date().toLocaleDateString()}`,
          doc.internal.pageSize.width - 50,
          20
        );
        startY = 80;
        totalPages++;
      };

      while (transactionIndex < transactions.length) {
        const transaction = transactions[transactionIndex];
        const {
          nomClient,
          userName,
          contact,
          paymentMethod,
          paidAmount,
          date,
        } = transaction;
        const transactionDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\nContact: ${contact}\nMéthode de paiement: ${paymentMethod}\nMontant payé: ${paidAmount} fbu\nDate: ${new Date(
          date
        ).toLocaleString()}`;
        doc.setFontSize(12);
        doc.text(transactionDetails, 10, startY);
        startY += 50; // Adjust the spacing

        // Check if there is enough space for the next transaction
        if (startY + 50 > doc.internal.pageSize.height) {
          addPageAndHeader();
        }
        transactionIndex++;
      }

      // Total Summary
      doc.setFontSize(12);
      doc.text(
        `Total Montant Payé: ${totalPaidAmount} fbu`,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 10,
        {
          align: "right",
          fontWeight: "bold",
        }
      );

      // Set total page count in the footer
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          `Page ${i} of ${totalPages}`,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 10
        );
      }

      // Finalize the PDF and trigger download
      doc.save(`Paiements_espèces_du_${selectedDateDailyCash}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingDailyCash(false);
    } catch (error) {
      toast.error(
        "Il y a une erreur de génération de pdf des données demandées"
      );
      setLoadingDailyCash(false);
    }
  };

  //pdf for daily banks data
  const [selectedDateDailyOthers, setSelectedDateDailyOthers] = useState("");
  const [loadingOthers, setLoadingOthers] = useState(false);

  const handleDateChangeDailyOthers = (event) => {
    const date = event.target.value;
    setSelectedDateDailyOthers(date);
  };

  const handleDownloadPDFOthers = async () => {
    try {
      setLoadingOthers(true);

      const date = new Date(selectedDateDailyOthers);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}/${month}/${day}`;

      const response = await axios.get(
        `${server}/payment-commande/daily-paid-amount-others/${year}/${month}/${day}`,
        { responseType: "json" }
      );

      const { transactions, totalPaidAmount } = response.data;

      if (!transactions || transactions.length === 0) {
        setLoadingOthers(false);
        toast.error("Aucune donnée disponible pour générer le rapport.");
        return;
      }

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport des paiements journaliers (dans les banques) du ${formattedDate}`,
        subject: "Les détails du rapport",
        author: "Your Company",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Title
      doc.setFontSize(18);
      doc.text(
        `Rapport des paiements journaliers (dans les banques) du ${formattedDate}`,
        10,
        50
      );

      // Display transactions
      let startY = 60; // Adjust the starting Y position
      let transactionIndex = 0;
      let totalPages = 1;

      const addPageAndHeader = () => {
        doc.addPage();
        // Add Company Logo and Header on each new page
        if (logoImgData) {
          doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
        }
        doc.setFontSize(14);
        doc.text("Bismos High Tech", 50, 20);
        doc.setFontSize(10);
        doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 50, 30);
        doc.text(
          "Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord",
          50,
          40
        );
        doc.setFontSize(18);
        doc.text(
          "Rapport des paiements journaliers (dans les banques)",
          50,
          60
        );
        startY = 80;
        totalPages++;
      };

      while (transactionIndex < transactions.length) {
        const transaction = transactions[transactionIndex];
        const {
          nomClient,
          userName,
          contact,
          paymentMethod,
          paidAmount,
          date,
        } = transaction;
        const transactionDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\nContact: ${contact}\nMéthode de paiement: ${paymentMethod}\nMontant payé: ${paidAmount} fbu\nDate: ${new Date(
          date
        ).toLocaleString()}`;
        doc.setFontSize(12);
        doc.text(transactionDetails, 10, startY);
        startY += 50; // Adjust the spacing

        // Check if there is enough space for the next transaction
        if (startY + 50 > doc.internal.pageSize.height) {
          addPageAndHeader();
        }
        transactionIndex++;
      }

      // Total paid amount
      doc.setFontSize(14);
      doc.text(`Total des paiements: ${totalPaidAmount} fbu`, 10, startY + 10);

      // Set total page count in the footer
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(
          `Page ${i} of ${totalPages}`,
          doc.internal.pageSize.width - 20,
          doc.internal.pageSize.height - 10
        );
      }

      // Save the PDF
      doc.save(`Paiements_journaliers_banques_${formattedDate}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingOthers(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(
        "Une erreur s'est produite lors de la récupération des données."
      );
      setLoadingOthers(false);
    }
  };

  //monthly cash
  const [selectedDateMonthlyCash, setSelectedDateMonthlyCash] = useState("");
  const [loadingMonthlyCash, setLoadingMonthlyCash] = useState(false);

  const handleDateChangeMonthlyCash = (event) => {
    setSelectedDateMonthlyCash(event.target.value);
  };

  const handleDownloadPDFMonthlyCash = async () => {
    try {
      setLoadingMonthlyCash(true);

      const date = new Date(selectedDateMonthlyCash);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const formattedDate = `${year}/${month}`;

      const response = await axios.get(
        `${server}/payment-commande/monthly-paid-amount-cash/${year}/${month}`,
        { responseType: "json" }
      );

      console.log("Response:", response.data); // Log the response data

      const { transactions, totalPaidAmount } = response.data;

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport des paiements mensuels en cash de ${formattedDate}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Title
      doc.setFontSize(18);
      doc.text(
        `Rapport des paiements mensuels en cash de ${formattedDate}`,
        10,
        50
      );

      // Display transactions
      let startY = 60; // Adjust the starting Y position
      transactions.forEach((transaction, index) => {
        const {
          nomClient,
          userName,
          contact,
          paymentMethod,
          paidAmount,
          date,
        } = transaction;
        const transactionDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\nContact: ${contact}\nMéthode de paiement: ${paymentMethod}\nMontant payé: ${paidAmount} fbu\nDate: ${new Date(
          date
        ).toLocaleString()}`;
        doc.setFontSize(12);

        // Check if there is enough space on the current page
        if (startY + 50 > doc.internal.pageSize.height) {
          doc.addPage(); // Add a new page if content exceeds available space
          startY = 10; // Reset startY for the new page
        }

        doc.text(transactionDetails, 10, startY);
        startY += 50; // Adjust the spacing
      });

      // Total paid amount
      doc.setFontSize(14);
      doc.text(`Total des paiements: ${totalPaidAmount} fbu`, 10, startY + 10);

      // Save the PDF
      doc.save(`Paiements_mensuels_cash_${formattedDate}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingMonthlyCash(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(
        "Une erreur s'est produite lors de la récupération des données."
      );
      setLoadingMonthlyCash(false);
    }
  };

  //geting monthly other methods

  const [selectedDateMonthlyOthers, setSelectedDateMonthlyOthers] =
    useState("");
  const [loadingMonthlyOthers, setLoadingMonthlyOthers] = useState(false);

  const handleDateChangeMonthlyOthers = (event) => {
    setSelectedDateMonthlyOthers(event.target.value);
  };

  const handleDownloadPDFMonthlyOthers = async () => {
    try {
      setLoadingMonthlyOthers(true);

      const date = new Date(selectedDateMonthlyOthers);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const formattedDate = `${year}/${month}`;

      const response = await axios.get(
        `${server}/payment-commande/monthly-paid-amount-others/${year}/${month}`,
        { responseType: "json" }
      );

      console.log("Response:", response.data); // Log the response data

      const { transactions, totalPaidAmount } = response.data;

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: `Rapport des paiements mensuels dans les banques de ${formattedDate}`,
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      const logoImgData = Logo; // Replace with your base64 encoded logo image
      if (logoImgData) {
        doc.addImage(logoImgData, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Title
      doc.setFontSize(18);
      doc.text(
        `Rapport des paiements mensuels dans le banques de ${formattedDate}`,
        10,
        50
      );

      // Display transactions
      let startY = 60; // Adjust the starting Y position
      transactions.forEach((transaction, index) => {
        const {
          nomClient,
          userName,
          contact,
          paymentMethod,
          paidAmount,
          date,
        } = transaction;
        const transactionDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\nContact: ${contact}\nMéthode de paiement: ${paymentMethod}\nMontant payé: ${paidAmount} fbu\nDate: ${new Date(
          date
        ).toLocaleString()}`;
        doc.setFontSize(12);

        // Check if there is enough space on the current page
        if (startY + 50 > doc.internal.pageSize.height) {
          doc.addPage(); // Add a new page if content exceeds available space
          startY = 10; // Reset startY for the new page
        }

        doc.text(transactionDetails, 10, startY);
        startY += 50; // Adjust the spacing
      });

      // Total paid amount
      doc.setFontSize(14);
      doc.text(`Total des paiements: ${totalPaidAmount} fbu`, 10, startY + 10);

      // Save the PDF
      doc.save(`Paiements_mensuels_banques_${formattedDate}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingMonthlyOthers(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(
        "Une erreur s'est produite lors de la récupération des données."
      );
      setLoadingMonthlyOthers(false);
    }
  };

  //debts people
  const [loadingCommandesWithDebts, setLoadingCommandesWithDebts] =
    useState(false);

  const handleDownloadPDFCommandesWithDebts = async () => {
    try {
      setLoadingCommandesWithDebts(true);

      const response = await axios.get(
        `${server}/commande/commandes-with-debts`,
        { responseType: "json" }
      );

      const commandes = response.data;

      // Create a new instance of jsPDF
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Set document properties
      doc.setProperties({
        title: "Rapport des clients avec des dettes",
        subject: "Les détails du rapport",
        author: "Bismos",
      });

      // Add Company Logo and Header
      if (Logo) {
        doc.addImage(Logo, "PNG", 10, 10, 20, 20); // Adjust logo position and size
      }

      // Business Information
      doc.setFontSize(14);
      doc.text("Bismos High Tech", 50, 20);
      doc.setFontSize(10);
      doc.text(`A. Vendeur: BISMOS NIF: ${nif}, RC:${rccm}`, 50, 30);
      doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", 50, 40);

      // Title
      doc.setFontSize(18);
      doc.text("Rapport des clients avec des dettes", 10, 50);

      // Display commandes with debts
      let startY = 60; // Adjust the starting Y position
      commandes.forEach((commande, index) => {
        const { nomClient, userName, contact, prestations } = commande;
        doc.setFontSize(12);

        // Check if there is enough space on the current page
        if (startY + 10 > doc.internal.pageSize.height - 10) {
          doc.addPage(); // Add a new page if content exceeds available space
          startY = 10; // Reset startY for the new page
        }

        const clientDetails = `Nom du client: ${nomClient}\nNom d'utilisateur: ${userName}\n\n\n\n\n\n\n\n\n\nContact: ${contact}`;
        doc.text(clientDetails, 10, startY);
        startY += 10;

        prestations.forEach((prestation, pIndex) => {
          if (startY + 50 > doc.internal.pageSize.height - 10) {
            doc.addPage(); // Add a new page if content exceeds available space
            startY = 10; // Reset startY for the new page
          }

          const prestationDetails = `Commande: ${prestation.commande
            }\nQuantité: ${prestation.qty}\nPrix unitaire: ${prestation.price_unitaire
            }\nPrix total: ${prestation.price_total}\nAvance: ${prestation.avance
            }\nReste: ${prestation.reste}\nDescription: ${prestation.desc
            }\nRelation: ${prestation.relation}\nDate de création: ${new Date(
              prestation.createdAt
            ).toLocaleString()}`;
          doc.text(prestationDetails, 10, startY);
          startY += 50; // Adjust the spacing
        });

        startY += 10; // Additional spacing between clients
      });

      // Save the PDF
      doc.save("Rapport_clients_dettes.pdf");

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoadingCommandesWithDebts(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(
        "Une erreur s'est produite lors de la récupération des données."
      );
      setLoadingCommandesWithDebts(false);
    }
  };

  //CHECKING MONEY
  const [selectedDateMoney, setSelectedDateMoney] = useState("");
  const [loadingMoney, setLoadingMoney] = useState(false);

  const handleDateChangeMoney = (event) => {
    setSelectedDateMoney(event.target.value);
  };

  const handleDownloadPDFMoney = async () => {
    try {
      setLoadingMoney(true);

      const date = new Date(selectedDateMoney);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");

      const response = await axios.get(
        `${server}/payment-commande/monthly-cash-payments/${year}/${month}`,
        {
          responseType: "json",
        }
      );

      const { depenseSum, depositSum, paymentSum } = response.data;

      const doc = new jsPDF();
      doc.text(`Monthly Cash Payments Report - ${year}/${month}`, 10, 10);
      doc.text(`Total Depense: ${depenseSum} FBU`, 10, 20);
      doc.text(`Total Deposit: ${depositSum} FBU`, 10, 30);
      doc.text(
        `Total Des depot et depense: ${Number(depositSum) + Number(depenseSum)
        } FBU`,
        10,
        40
      );
      doc.text(`Total Cash Payments: ${paymentSum} FBU`, 10, 50);
      doc.text(
        `Argent restant dans la caisse: ${Number(paymentSum) - Number(depositSum) - Number(depenseSum)
        } FBU`,
        10,
        60
      );

      doc.save(`Monthly_Cash_cheking_Payments_${year}_${month}.pdf`);

      toast.success("Le rapport PDF a été généré avec succès.");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error(
        "Une erreur s'est produite lors de la récupération des données."
      );
      setLoading(false);
    }
  };
  const columns = [
    { field: "createdAt", headerName: "Créé le", flex: 1 },
    { field: "name", headerName: "Nom", flex: 1 },
    { field: "desc", headerName: "Description", flex: 1 },
    { field: "userName", headerName: "Effectué par", flex: 1 },
    { field: "status", headerName: "Statut", flex: 1 },
    {
      field: "edit",
      headerName: "Éditer",
      width: 100,
      renderCell: (params) => (
        <Link to={`/PendingProductEdit/${params.row.id}`}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            Éditer
          </Button>
        </Link>
      ),
    },
  ];

  const rows = pendingProducts.map((item) => ({
    id: item._id,
    name: item.name,
    desc: item.desc,
    userName: item.userName,
    status: "En attente",
    createdAt: item.createdAt.slice(0, 10),
  }));

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Bienvenu au Bureau d'acceuil" />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setOpenPasswordDialog(true)}
          startIcon={<CheckBox />}
          sx={{ height: "40px" }}
        >
          Changer le mot de passe de paiement
        </Button>
      </FlexBetween>

      <Box display="flex" flexWrap="wrap" gap="20px" mt="20px">
        <Box flex="1 1 300px">
          <StatBox
            title="L'argent disponible dans la caisse aujourd'hui"
            value={dailyCash}
            increase="fbu"
            description="Aujourd'hui"
            icon={
              <Payments
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px">
          <StatBox
            title="L'argent entrer dans les banques aujourd'hui"
            value={dailyBanks}
            increase="fbu"
            description="Aujourd'hui"
            icon={
              <AccountBalance
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px">
          <StatBox
            title="L'argent disponible dans la caisse ce mois"
            value={monthlyCash}
            increase="fbu"
            description="Ce mois"
            icon={
              <MonetizationOn
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px">
          <StatBox
            title="L'argent disponible sur les banques ce mois"
            value={dailySales}
            increase="fbu"
            description="Cet mois"
            icon={
              <AccountBalance
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box flex="1 1 300px">
          <StatBox
            title="Depense journaliere"
            increase="fbu"
            value={dailyDepenses}
            description="Depuis 7h30"
            icon={
              <Receipt
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box flex="1 1 300px">
          <StatBox
            title="Les commandes d'aujourdhui"
            value={dailySales}
            increase="fbu"
            description="Depuis 7h30"
            icon={
              <ShoppingCart
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box flex="1 1 300px">
          <StatBox
            title="Les commandes mensuelles"
            value={monthlySales}
            increase="fbu"
            description="Dans ce mois"
            icon={
              <Assessment
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>

        <Box flex="1 1 300px">
          <StatBox
            title="Les commandes annuelles"
            value={yearlySales}
            increase="fbu"
            description="Cette année"
            icon={
              <TrendingUp
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
      <Box mt="20px">
        <DataGrid rows={rows} columns={columns} autoHeight />
      </Box>
      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les paiements journaliers en cash
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner la Date"
              type="date"
              value={selectedDateDailyCash}
              onChange={handleDateChangeDailyCash}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFDailyCash}
              disabled={!selectedDateDailyCash || loadingDailyCash}
            >
              {loadingDailyCash ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les paiements journaliers (dans les banques)
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner la Date"
              type="date"
              value={selectedDateDailyOthers}
              onChange={handleDateChangeDailyOthers}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFOthers}
              disabled={!selectedDateDailyOthers || loadingOthers}
            >
              {loadingOthers ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les paiements mensuels en cash
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner la Date"
              type="date"
              value={selectedDateMonthlyCash}
              onChange={handleDateChangeMonthlyCash}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFMonthlyCash}
              disabled={!selectedDateMonthlyCash || loadingMonthlyCash}
            >
              {loadingMonthlyCash ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les paiements mensuels dans les banques
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner la Date"
              type="date"
              value={selectedDateMonthlyOthers}
              onChange={handleDateChangeMonthlyOthers}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFMonthlyOthers}
              disabled={!selectedDateMonthlyOthers || loadingMonthlyOthers}
            >
              {loadingMonthlyOthers ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les commandes journalieres
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Selectionner la Date"
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDF}
              disabled={!selectedDate || loading}
            >
              {loading ? <CircularProgress size={24} /> : "Télecharger le PDF"}
            </Button>
          </Box>
        </Paper>
        {/* {error && <Box color="error.main">{error}</Box>} */}
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Les commandes mensuelles
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Selectionner le mois"
              type="month"
              value={selectedDate1}
              onChange={handleDateChange1}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDF1}
              disabled={!selectedDate1 || loading1}
            >
              {loading ? <CircularProgress size={24} /> : "Télecharger le PDF"}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Le rapport des intérets mensuels
          </Typography>
          <Box display="flex" justifyContent="space-between">
            {/* <TextField
              id="year"
              label="Select Year"
              type="number"
              value={selectedYear2}
              onChange={handleYearChange2}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <TextField
              id="month"
              label="Selectionner le mois"
              type="month"
              value={selectedMonth2}
              onChange={handleMonthChange2}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDF2}
              disabled={loading2}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                "Télecharger le rapport"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Le rapport des intérets annuels
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="year"
              label="Sélectionner l'année"
              type="number"
              value={selectedYear3}
              onChange={handleYearChange3}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDF3}
              disabled={loading3}
            >
              {loading3 ? (
                <CircularProgress size={24} />
              ) : (
                "Télecharger le rapport"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Rapport des clients avec des dettes
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFCommandesWithDebts}
              disabled={loadingCommandesWithDebts}
            >
              {loadingCommandesWithDebts ? (
                <CircularProgress size={24} />
              ) : (
                "Télécharger le PDF"
              )}
            </Button>
          </Box>
        </Paper>
      </Box>

      <Box flex="1 1 300px">
        <Paper sx={{ p: 2, my: 2 }}>
          <Typography variant="h6" gutterBottom>
            Vérification des dépots de l'argent selon le mois choix(inclus les
            jours)
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <TextField
              id="date"
              label="Sélectionner la Date"
              type="date"
              value={selectedDateMoney}
              onChange={handleDateChangeMoney}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadPDFMoney}
              disabled={!selectedDateMoney || loadingMoney}
            >
              {loading ? <CircularProgress size={24} /> : "Télécharger le PDF"}
            </Button>
          </Box>
        </Paper>
      </Box>

      <ChangePasswordDialog
        open={openPasswordDialog}
        handleClose={() => setOpenPasswordDialog(false)}
      />
    </Box>
  );
};

export default Dashboard;
