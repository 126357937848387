import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "server";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";
import { nif } from "server";
import { rccm } from "server";

const RetraitDetails = () => {
  const { id } = useParams(); // Extract ID from URL
  const [Details, setDetails] = useState({});
  const pdfRef = useRef();

  // Fetch payment details based on ID using useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${server}/retrait/api/retrait/${id}`);
        setDetails(response.data.sale); // Assuming response structure
      } catch (error) {
        console.error("Error fetching payment details:", error);
        // Handle errors (e.g., display error message)
      }
    };

    fetchData();
  }, [id]);

  if (!Details) {
    return <p>Loading payment details...</p>; // Display loading indicator
  }
  const handleDownloadPDF = () => {
    const input = pdfRef.current;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );
      pdf.save("Historique_de_paiement.pdf");
    });
  };

  return (
    <div class="invoice-wrapper" id="print-area">
      <div class="invoice" ref={pdfRef}>
        <div class="invoice-container">
          <div class="invoice-head">
            <div class="invoice-head-top">
              <div class="invoice-head-top-left text-start">
                <img src={Logo} />
              </div>
              <div class="invoice-head-top-right text-end">
                <h3>Detaile des retraits</h3>
              </div>
            </div>
            <div class="hr"></div>
            <div class="invoice-head-middle">
              <div class="invoice-head-middle-left text-start">
                <p>
                  <span class="text-bold">Date</span>:{" "}
                  {Details.createdAt?.slice(0, 10)}
                </p>
              </div>
            </div>
            <div class="hr"></div>
            <div class="invoice-head-bottom">
              <div class="invoice-head-bottom-left">
                <ul>
                  <li class="text-bold">Bismos High Tech:</li>
                  <li> NIF: {nif}/RC: {rccm}</li>
                  <li>Avenue: KIGARAMA, N0: 21</li>
                  <li>centre Fiscal:Ressort Nord </li>
                  <li>
                    Secteur d'activité: COM. General Forme Juridique: Personne
                    Morale
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="overflow-view">
            <div class="invoice-body">
              <table>
                <thead>
                  <tr>
                    <td class="text-bold">Nom du produit</td>
                    <td class="text-bold">Description</td>
                    <td class="text-bold">Quantité retire</td>
                  </tr>
                </thead>
                <tbody>
                  {Details.products &&
                    Details.products.map((lineItem) => (
                      <tr>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 pl-0 color-black">
                          {lineItem.productName}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 color-black">
                          {lineItem.desc}
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 color-black">
                          {lineItem.quantity}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div class="invoice-foot text-center">
            <p>
              <span class="text-bold text-end">NOTE:&nbsp;</span>This is
              computer generated receipt and does not require physical
              signature.
            </p>
          </div>
        </div>
      </div>
      <div className="invoice-btns">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
          onClick={handleDownloadPDF} // Corrected onClick handler
        >
          Télécharger
        </Button>
      </div>
    </div>
  );
};

export default RetraitDetails;
