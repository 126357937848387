import React, { useEffect, useState } from "react";
import {
  AccountBalance,
  AddBox,
  ArrowLeftSharp,
  Badge,
  LocationSearching,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import "./employeeEdit.css";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Button,
  TextField,
  Typography,
  Box,
  useMediaQuery,
  FormControlLabel,
  Switch,
} from "@mui/material";
import FormLoan from "scenes/fromLoan";
import Popup from "components/Popup";
import { backend_url } from "server";
import { server } from "server";
import FormHoliday from "scenes/formHolidays";
import BankOverdraft from 'scenes/bankOverdraft';

const EmployeeEdit = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const [Open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const { id } = useParams();
  const [employeeInfo, setEmployeeInfo] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [FormData, setFormData] = useState({
    name: "",
    cni: "",
    role: "",
    phone: "",
    address: "",
    bank: "",
    salary: 0,
    contractual: false,
  });

  const [openOverdraft, setOpenOverdraft] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${server}/employee/update-employee/${id}`,
        FormData,
        { withCredentials: true }
      );
      toast.success("Employee modifié avec succes");
      setTimeout(() => {
        window.location.replace("/employés");
      }, 1000);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleImage = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(`${server}/employee/update-avatar/${id}`, {
            avatar: reader.result,
          })
          .then((response) => {
            toast.success("Image modifié avec succes");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    const fetchEmployee = async () => {
      const response = await axios.get(`${server}/employee/an-employee/${id}`);
      setEmployeeInfo(response.data.OneEmployee);
      setFormData({
        name: response.data.OneEmployee.name,
        cni: response.data.OneEmployee.cni,
        role: response.data.OneEmployee.role,
        phone: response.data.OneEmployee.phoneNumber,
        address: response.data.OneEmployee.address,
        bank: response.data.OneEmployee.bank_account,
        salary: response.data.OneEmployee.salary,
        contractual: response.data.OneEmployee.contractual,
      });
    };
    fetchEmployee();
  }, [id]);

  return (
    <>
      {employeeInfo ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: isMobile ? 2 : 5,
          }}
        >
          <Box
            className="userTitleContainer"
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              gap: 2,
              width: '100%',
              justifyContent: 'space-between',
              mb: 3
            }}
          >
            {/* Left side - Back button and title */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Link to={"/employés"}>
                <Button
                  color="secondary"
                  variant="contained"
                  startIcon={<ArrowLeftSharp />}
                >
                  Retour
                </Button>
              </Link>
              <Typography variant="h4">
                Modifier l'employé
              </Typography>
            </Box>

            {/* Right side - Action buttons */}
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexWrap: isMobile ? 'wrap' : 'nowrap',
                justifyContent: isMobile ? 'center' : 'flex-end'
              }}
            >
              <Button
                color="secondary"
                variant="contained"
                startIcon={<AddBox />}
                onClick={() => setOpenPopup(!openPopup)}
              >
                Créditer
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setOpen(!Open)}
              >
                Donner un congé
              </Button>
              <Button
                color="secondary"
                variant="contained"
                startIcon={<AddBox />}
                onClick={() => setOpenOverdraft(!openOverdraft)}
              >
                Découvert Bancaire
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              justifyContent: "center",
              marginTop: 4,
              width: "100%",
            }}
          >
            <Box sx={{ flex: 1, textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <img
                  src={`${employeeInfo?.avatar?.url}`}
                  alt=""
                  className="userShowImg"
                  style={{
                    width: isMobile ? "150px" : "200px",
                    height: isMobile ? "150px" : "200px",
                    borderRadius: "10px",
                  }}
                />
                <Typography variant="h5">{employeeInfo.name}</Typography>
                <Typography variant="body1">{employeeInfo.role}</Typography>
              </Box>
              <Box sx={{ marginTop: 4 }}>
                <Typography variant="h6">Détails</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PermIdentity />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {employeeInfo.name}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Badge />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {employeeInfo.cni}
                    </Typography>
                  </Box>
                  <Typography variant="h6" sx={{ marginTop: 2 }}>
                    Autre Information
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <PhoneAndroid />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      +257({employeeInfo.phoneNumber})
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AccountBalance />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {employeeInfo.bank_account}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LocationSearching />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {employeeInfo.address}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ flex: 2 }}>
              <Typography
                variant="h6"
                sx={{ marginBottom: 2, textAlign: "center" }}
              >
                Information
              </Typography>
              <form
                className="userUpdateForm"
                onSubmit={handleUpdate}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div className="userUpdateLeft">
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Nom et Prenom"
                    value={FormData.name}
                    onChange={(e) =>
                      setFormData({ ...FormData, name: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Numero du CNI"
                    value={FormData.cni}
                    onChange={(e) =>
                      setFormData({ ...FormData, cni: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Contact"
                    value={FormData.phone}
                    onChange={(e) =>
                      setFormData({ ...FormData, phone: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Role"
                    value={FormData.role}
                    onChange={(e) =>
                      setFormData({ ...FormData, role: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Adresse"
                    value={FormData.address}
                    onChange={(e) =>
                      setFormData({ ...FormData, address: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Compte bancaire"
                    value={FormData.bank}
                    onChange={(e) =>
                      setFormData({ ...FormData, bank: e.target.value })
                    }
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Salaire"
                    type="number"
                    value={FormData.salary}
                    onChange={(e) =>
                      setFormData({ ...FormData, salary: e.target.value })
                    }
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={FormData.contractual}
                        onChange={(e) =>
                          setFormData({ ...FormData, contractual: e.target.checked })
                        }
                        color="secondary"
                      />
                    }
                    label={FormData.contractual ? "Employé contractuel" : "Employé non contractuel"}
                  />
                </div>
                <div className="userUpdateRight">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="userUpdateImg"
                      src={`${employeeInfo?.avatar?.url}`}
                      alt=""
                      style={{
                        width: isMobile ? "150px" : "200px",
                        height: isMobile ? "150px" : "200px",
                        borderRadius: "10px",
                      }}
                    />
                    <label htmlFor="file">
                      <Publish className="userUpdateIcon" />
                    </label>
                    <input
                      type="file"
                      id="file"
                      style={{ display: "none" }}
                      onChange={handleImage}
                    />
                  </Box>
                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ marginBottom: "6px" }}
                    className="userUpdateButton"
                    type="submit"
                  >
                    Modifié
                  </Button>
                </div>
              </form>
            </Box>
          </Box>
        </Box>
      ) : (
        <p>Loading ...</p>
      )}
      <Popup
        title="Loan from"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormLoan
          employeeName={employeeInfo?.name}
          employeeSal={employeeInfo?.salary}
          setOpenPopup={setOpenPopup}
          id={id}
        />
      </Popup>
      <Popup
        title="Formulaire des congés"
        Open={Open}
        setOpen={setOpen}
      >
        <FormHoliday
          employeeName={employeeInfo?.name}
          setOpen={setOpen}
          id={id}
        />
      </Popup>
      <Popup
        title="Bank Overdraft Request"
        openPopup={openOverdraft}
        setOpenPopup={setOpenOverdraft}
      >
        <BankOverdraft
          employeeName={employeeInfo?.name}
          employeeSal={employeeInfo?.salary}
          setOpenOverdraft={setOpenOverdraft}
          id={id}
        />
      </Popup>
    </>
  );
};

export default EmployeeEdit;
