import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    Alert,
    IconButton,
    InputAdornment
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { server } from 'server';
import {
    VisibilityOff,
    Visibility
} from '@mui/icons-material';

const OTPInput = ({ length, value, onChange, showPassword, togglePassword }) => {
    const inputRefs = useRef([]);

    useEffect(() => {
        // Focus first input on mount
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    const handleChange = (index, e) => {
        const newValue = e.target.value;
        if (newValue.length <= 1 && /^[0-9]*$/.test(newValue)) {
            onChange(index, newValue);
            // Move to next input if value is entered
            if (newValue && index < length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, e) => {
        // Move to previous input on backspace if current input is empty
        if (e.key === 'Backspace' && !value[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', mb: 2 }}>
            {Array.from({ length }, (_, index) => (
                <TextField
                    key={index}
                    inputRef={el => inputRefs.current[index] = el}
                    type={showPassword ? "text" : "password"}
                    value={value[index] || ''}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    inputProps={{
                        maxLength: 1,
                        style: { 
                            textAlign: 'center',
                            width: '40px',
                            height: '40px',
                            padding: '0',
                            fontSize: '20px'
                        }
                    }}
                />
            ))}
            <IconButton onClick={togglePassword} sx={{ ml: 1 }}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </Box>
    );
};

const ChangePasswordDialog = ({ open, handleClose }) => {
    const [currentPassword, setCurrentPassword] = useState(['', '', '', '']);
    const [newPassword, setNewPassword] = useState(['', '', '', '']);
    const [confirmPassword, setConfirmPassword] = useState(['', '', '', '']);
    const [error, setError] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handlePasswordChange = (setter, index, value) => {
        setter(prev => {
            const newValue = [...prev];
            newValue[index] = value;
            return newValue;
        });
    };

    const handleSubmit = async () => {
        try {
            const currentPass = currentPassword.join('');
            const newPass = newPassword.join('');
            const confirmPass = confirmPassword.join('');

            // Validate inputs
            if (!currentPass || !newPass || !confirmPass) {
                setError('Tous les champs sont obligatoires');
                return;
            }

            if (newPass !== confirmPass) {
                setError('Les nouveaux mots de passe ne correspondent pas');
                return;
            }

            if (newPass.length !== 4) {
                setError('Le nouveau mot de passe doit contenir 4 caractères');
                return;
            }

            // First verify current password
            const verifyResponse = await axios.post(`${server}/loan/api/login`, {
                password: currentPass
            });

            if (verifyResponse.data.message === 'Login successful') {
                // Update password
                const updateResponse = await axios.patch(`${server}/loan/api/change-password`, {
                    currentPassword: currentPass,
                    newPassword: newPass
                });

                if (updateResponse.data.success) {
                    toast.success('Mot de passe modifié avec succès');
                    handleClose();
                    // Reset form
                    setCurrentPassword(['', '', '', '']);
                    setNewPassword(['', '', '', '']);
                    setConfirmPassword(['', '', '', '']);
                    setError('');
                }
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Une erreur est survenue');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Changer le mot de passe de paiement</DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 2 }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        Le mot de passe doit contenir exactement 4 chiffres
                    </Typography>
                    
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Mot de passe actuel
                    </Typography>
                    <OTPInput
                        length={4}
                        value={currentPassword}
                        onChange={(index, value) => handlePasswordChange(setCurrentPassword, index, value)}
                        showPassword={showCurrentPassword}
                        togglePassword={() => setShowCurrentPassword(!showCurrentPassword)}
                    />

                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Nouveau mot de passe
                    </Typography>
                    <OTPInput
                        length={4}
                        value={newPassword}
                        onChange={(index, value) => handlePasswordChange(setNewPassword, index, value)}
                        showPassword={showNewPassword}
                        togglePassword={() => setShowNewPassword(!showNewPassword)}
                    />

                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Confirmer le nouveau mot de passe
                    </Typography>
                    <OTPInput
                        length={4}
                        value={confirmPassword}
                        onChange={(index, value) => handlePasswordChange(setConfirmPassword, index, value)}
                        showPassword={showConfirmPassword}
                        togglePassword={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuler</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Changer
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangePasswordDialog; 