import { Box, Button, Typography, useTheme, TextField, InputAdornment } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import { server } from "server";
import { Link } from "react-router-dom";
import { ArrowLeftSharp, DeleteOutline } from "@mui/icons-material";
import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";
import SearchIcon from "@mui/icons-material/Search";

const BankOverdraftList = () => {
    const theme = useTheme();
    const [overdrafts, setOverdrafts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }).format(date);
    };

    const formatCurrency = (value) => {
        return value.toLocaleString() + " FBU";
    };

    useEffect(() => {
        fetchOverdrafts();
    }, []);

    const fetchOverdrafts = async () => {
        try {
            const response = await axios.get(`${server}/loan/overdraft`);
            setOverdrafts(response.data);
        } catch (error) {
            toast.error("Erreur lors du chargement des découverts");
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredOverdrafts = overdrafts.filter((overdraft) =>
        overdraft.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleDelete = async (id) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce découvert bancaire ?")) {
            try {
                await axios.delete(`${server}/loan/overdraft/${id}`);
                toast.success("Découvert bancaire supprimé avec succès");
                // Refresh the list
                fetchOverdrafts();
            } catch (error) {
                toast.error(error.response?.data?.message || "Erreur lors de la suppression");
            }
        }
    };

    const columns = [
        {
            field: "name",
            headerName: "Employé",
            flex: 1,
        },
        {
            field: "totalAmount",
            headerName: "Montant initial",
            flex: 1,
            renderCell: (params) => formatCurrency(params.value),
        },
        {
            field: "remainingBalance",
            headerName: "Solde restant",
            flex: 1,
            renderCell: (params) => formatCurrency(params.value),
        },
        {
            field: "monthlyPayment",
            headerName: "Mensualité",
            flex: 1,
            renderCell: (params) => formatCurrency(params.value),
        },
        {
            field: "monthsLeft",
            headerName: "Mois restants",
            width: 120,
        },
        {
            field: "hasInterest",
            headerName: "Intérêts",
            width: 100,
            renderCell: (params) => (
                <Box
                    sx={{
                        backgroundColor: params.value ? theme.palette.warning.main : theme.palette.grey[500],
                        padding: "5px 10px",
                        borderRadius: "4px",
                        color: "white",
                    }}
                >
                    {params.value ? "2%" : "Non"}
                </Box>
            ),
        },
        {
            field: "status",
            headerName: "Statut",
            width: 120,
            renderCell: (params) => (
                <Box
                    sx={{
                        backgroundColor:
                            params.value === "completed" ? theme.palette.success.main :
                                params.value === "active" ? theme.palette.warning.main :
                                    theme.palette.error.main,
                        padding: "5px 10px",
                        borderRadius: "4px",
                        color: "white",
                    }}
                >
                    {params.value === "completed" ? "Terminé" :
                        params.value === "active" ? "En cours" : "Annulé"}
                </Box>
            ),
        },
        {
            field: "createdAt",
            headerName: "Date création",
            flex: 1,
            renderCell: (params) => formatDate(params.value),
        }, {
            field: "actions",
            headerName: "Actions",
            width: 120,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => handleDelete(params.row._id)}
                    disabled={params.row.status === "active"}
                >
                    Supprimer
                </Button>
            )
        }
    ];

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Link to="/employés">
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<ArrowLeftSharp />}
                    >
                        Retour
                    </Button>
                </Link>
                <Header title="Découverts bancaires" />
            </FlexBetween>
            <br />
            <TextField
                variant="standard"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Rechercher un employé..."
                sx={{
                    width: "300px",
                    mb: 2,
                    "& .MuiInputBase-root": {
                        color: theme.palette.secondary[100],
                    },
                    "& .MuiInput-underline:before": {
                        borderBottomColor: theme.palette.secondary[100],
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: theme.palette.primary[500],
                    },
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: theme.palette.secondary[100] }} />
                        </InputAdornment>
                    ),
                }}
            />

            <Box mt="20px" height="75vh">
                <DataGrid
                    loading={loading}
                    rows={filteredOverdrafts}
                    columns={columns}
                    getRowId={(row) => row._id}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.primary.light,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default BankOverdraftList; 