import React, { useEffect, useState } from "react";
import { Box, Button, InputAdornment, TextField, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import axios from "axios";
import { server } from "server";
import FormProductStock from "scenes/formProductStock";
import Popup from "components/Popup";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { Link } from "react-router-dom";
import { ArrowLeftSharp } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search"

const PendingProduct = () => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);
  const [pendingProducts, setPendingProducts] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchPendingProducts = async () => {
      try {
        await axios.get(`${server}/product/pendingProducts`).then((res) => {
          setPendingProducts(res.data);
        });
      } catch (error) {
        console.error("Error fetching pending products:", error);
      }
    };
    fetchPendingProducts();
  }, []);

  const columns = [
    { field: "createdAt", headerName: "Created At", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "desc", headerName: "Description", flex: 1 },
    { field: "userName", headerName: "Effectue par", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <Link to={"/PendingProductEdit/" + params.row.id}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            Edit
          </Button>
        </Link>
      ),
    },
  ];

  const row = [];
  pendingProducts &&
    pendingProducts.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        desc: item.desc,
        userName: item.userName,
        status: "En attente",
        stock: item.stock,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <Box m="1.5rem 2.5rem">
      <Link to={"/stocks"}>
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ArrowLeftSharp /> retour
        </Button>
      </Link>
      <br />
      <Header
        title="Les Produits en attente"
        subtitle="Liste des Produits en attente"
      />
      <TextField
        variant="standard"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Rechercher un produit..."
        sx={{
          width: "300px",
          mb: 2,
          "& .MuiInputBase-root": {
            color: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary[500],
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: theme.palette.secondary[100] }} />
            </InputAdornment>
          ),
        }}
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: DataGridCustomToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <Popup
        title="Fromulaire d'un produit"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormProductStock setOpenPopup={setOpenPopup} />
      </Popup>
    </Box>
  );
};

export default PendingProduct;
