import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from "@mui/x-data-grid";
import Header from "components/Header";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import FormEmployee from "scenes/formemployee";
import Popup from "components/Popup";
import {
  ArrowLeftSharp,
  CancelPresentationSharp,
  CheckBox,
  DeleteOutline,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import "./loan.css";
import axios from "axios";
import { server } from "server";
import { toast } from "react-toastify";
import PopupConfirm from "components/PopupConfirm";
import FlexBetween from "components/FlexBetween";

const Loan = () => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);
  const [loan, setLoan] = useState([]);
  const [userId, setUserId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLoans();
  }, []);

  const fetchLoans = async () => {
    try {
      const res = await axios.get(`${server}/loan/get-all-loans`);
      const transformedLoans = res.data.allloans.map(item => ({
        id: item._id,
        employeeName: item.name || item.employeeName,
        totalAmount: item.credit || item.totalAmount,
        monthlyPayment: item.monthlyPayment,
        remainingBalance: item.remainedCredits,
        monthsLeft: item.numMonths,
        status: transformStatus(item.status),
        createdAt: item.createdAt,
        paymentHistory: item.paymentHistory || []
      }));
      setLoan(transformedLoans);
    } catch (error) {
      toast.error("Erreur lors du chargement des crédits");
    } finally {
      setLoading(false);
    }
  };

  const transformStatus = (status) => {
    if (typeof status === 'boolean') {
      return status ? 'active' : 'completed';
    }
    return status;
  };

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`${server}/loan/delete-loan/${id}`);
      toast.success(res.data.message);
      fetchLoans();
    } catch (error) {
      toast.error(error.response?.data?.message || "Erreur lors de la suppression");
    }
  };

  const formatNumber = (number) => {
    if (!number) return "0 FBU";
    return number.toLocaleString('fr-BI', {
      style: 'currency',
      currency: 'BIF',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('fr-FR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Date de création",
      flex: 1,
      renderCell: (params) => formatDate(params.value)
    },
    {
      field: "employeeName",
      headerName: "Nom et Prénom",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "totalAmount",
      headerName: "Montant total",
      flex: 1,
      renderCell: (params) => formatNumber(params.value)
    },
    {
      field: "monthlyPayment",
      headerName: "Paiement mensuel",
      flex: 1,
      renderCell: (params) => formatNumber(params.value)
    },
    {
      field: "remainingBalance",
      headerName: "Solde restant",
      flex: 1,
      renderCell: (params) => formatNumber(params.value)
    },
    {
      field: "monthsLeft",
      headerName: "Mois restants",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Statut",
      width: 120,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor:
              params.value === "completed" ? theme.palette.success.main :
                params.value === "active" ? theme.palette.warning.main :
                  theme.palette.error.main,
            padding: "5px 10px",
            borderRadius: "4px",
            color: "white",
          }}
        >
          {params.value === "completed" ? "Terminé" :
            params.value === "active" ? "En cours" : "Annulé"}
        </Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Box>
          <Button
          >
            <DeleteOutline
              className="userListDelete"
              onClick={() => {
                setUserId(params.row.id);
                setOpenDelete(true);
              }}
              sx={{
                cursor: 'pointer',
                color: theme.palette.error.main,
                '&:hover': { color: theme.palette.error.dark }
              }}
            />
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Link to="/employés">
          <Button
            color="secondary"
            variant="contained"
            startIcon={<ArrowLeftSharp />}
          >
            Retour
          </Button>
        </Link>
        <Header title="Crédits" subtitle="Liste des credits" />
      </Box>

      <Box
        height="75vh"
        sx={{
          mt: 2,
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={loan}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: CustomLoadingOverlay
          }}
          loading={loading}
          getRowId={(row) => row.id}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableSelectionOnClick
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Box>

      <PopupConfirm
        title="Voulez-vous supprimer le crédit ?"
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <FlexBetween>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              handleDelete(userId);
              setOpenDelete(false);
            }}
            startIcon={<CheckBox />}
          >
            Oui
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenDelete(false)}
            startIcon={<CancelPresentationSharp />}
          >
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
    </Box>
  );
};

const CustomLoadingOverlay = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography>Chargement des crédits...</Typography>
    </Box>
  );
};

export default Loan;
