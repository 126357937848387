import { Box, Grid, TextField, InputAdornment } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import FormEmployee from "scenes/formemployee";
import Popup from "components/Popup";
import {
  CheckBox,
  DeleteOutline,
  DeleteOutlined,
  HolidayVillageTwoTone,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import "./employe.css";
import axios from "axios";
import { server } from "server";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "@mui/material";
import DataGridEmployeToolbar from "components/DataGridEmployeToolbar";
import PopupConfirm from "components/PopupConfirm";
import FlexBetween from "components/FlexBetween";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import PersonIcon from "@mui/icons-material/Person";
import PopupPreview from "components/PopupPreview";
import SearchIcon from "@mui/icons-material/Search";
import { nif } from "server";
import { rccm } from "server";

const Employees = () => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [userId, setUserId] = useState("");

  // password variable
  const [password, setPassword] = useState(['', '', '', '']); // Array for 4-character password input
  const [username, setUsername] = useState(''); // For superadmin username

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    axios.get(`${server}/employee/get-all-employees`).then((res) => {
      setEmployee(res.data.allemployees);
    });
  }, []);

  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  // password function validation
  const handleChange = (index, value) => {
    const newPassword = [...password];
    newPassword[index] = value.slice(0, 1); // Only take the first character
    setPassword(newPassword);

    // Focus next input automatically if there's a character entered
    if (value && index < password.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleSubmit = async () => {
    const completePassword = password.join('');
    if (completePassword.length !== 4) {
      toast.error("Veuillez entrer un code à 4 chiffres");
      return;
    }

    try {
      const response = await axios.post(`${server}/loan/api/login`, {
        password: completePassword,
      });
      handleViewPaymentDetails();
      setOpen(false);
      setPassword(['', '', '', '']);
      toast.success("Authentification réussie");
    } catch (error) {
      toast.error(error.response?.data?.message);
      setPassword(['', '', '', '']); // Clear password on error
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(`${server}/employee/delete-employee/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  };
  const accountNumber1 = "702-094799-02-67";
  const accountNumber2 = "702-14741-01-91";

  // Function to generate the employee payments PDF
  const handleViewPaymentDetails = async () => {
    try {
      const response = await axios.get(`${server}/loan/payment/preview`);
      const employeePayments = response.data.employeePayments;
  
      // Formatting functions
      const formatNumber = (number) => new Intl.NumberFormat("en-US").format(number);
      const totalPayment = employeePayments.reduce((total, payment) => total + payment.payment, 0);
  
      const doc = new jsPDF();
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 15;
      let currentY = margin;
  
      // Add header to each page
      const addHeader = () => {
        doc.setFont("helvetica", "normal");
        
        // Logo
        if (Logo) {
          try {
            doc.addImage(Logo, "PNG", margin, 10, 20, 20);
          } catch (error) {
            console.warn("Error loading logo:", error);
          }
        }
  
        // Company Info
        const today = new Date();
        const formattedDate = `${String(today.getDate()).padStart(2, "0")}-${
          String(today.getMonth() + 1).padStart(2, "0")}-${today.getFullYear()}`;
  
        doc.setFontSize(9);
        doc.text("Bismos", pageWidth - margin, 15, { align: "right" });
        doc.text(`A. Vendeur: BISMOS NIF: ${nif}; RC:${rccm}`, pageWidth - margin, 20, { align: "right" });
        doc.text("Avenue: KIGARAMA, N0: 21, centre Fiscal:Ressort Nord", pageWidth - margin, 25, { align: "right" });
        doc.text(`Fait le: ${formattedDate}`, pageWidth - margin, 30, { align: "right" });
      };
  
      addHeader();
      currentY = 40;
  
      // Document Title
      doc.setFontSize(13);
      doc.setFont("helvetica", "bold");
      doc.text("Paiement des employés de BISMOS pour le mois de______", margin, currentY);
      currentY += 15;
  
      // Recipient Information
      doc.setFontSize(11);
      doc.text("Concerne : Paiement des salaires du personnel", margin, currentY);
      currentY += 8;
      doc.text("À Monsieur le Directeur de l'INTERBANK", margin, currentY);
      currentY += 15;
  
      // Payment Instructions as a continuous paragraph
      const paymentText = [
        { text: "Veuillez débiter mon compte No ", bold: false },
        { text: "702-094799-02-67", bold: true },
        { text: ", ouvert au nom de BISMOS, d'un montant en chiffre de ", bold: false },
        { text: `${totalPayment} FBU` , bold: true },
        { text: ", montant en lettres: ___________________________, pour le crédit du compte No ", bold: false },
        { text: "702-14741-01-91", bold: true },
        { text: ", ouvert au nom de l'UCODE MF, pour le paiement des salaires du personnel de l'imprimerie BISMOS.", bold: false }
      ];
  
      let currentLine = [];
      let currentWidth = 0;
      const maxWidth = pageWidth - margin * 2;
  
      paymentText.forEach((segment, index) => {
        doc.setFont("helvetica", segment.bold ? "bold" : "normal");
        
        const words = segment.text.split(' ');
        words.forEach(word => {
          const wordWidth = doc.getTextWidth(word + ' ');
          
          if (currentWidth + wordWidth > maxWidth) {
            // Draw current line
            doc.text(currentLine.join(' '), margin, currentY);
            currentY += 8;
            
            // Reset line
            currentLine = [word];
            currentWidth = doc.getTextWidth(word + ' ');
          } else {
            currentLine.push(word);
            currentWidth += wordWidth;
          }
        });
  
        // Draw remaining text on last segment
        if (index === paymentText.length - 1) {
          doc.text(currentLine.join(' '), margin, currentY);
          currentY += 8;
        }
      });
  
      currentY += 10;

      doc.setFontSize(11);
      doc.text(
        "La liste des employés ci-dessous est jointe à la présente demande de paiement.",
        margin,
        currentY 
      );
      currentY += 5;
  
      // Employee Payments Table
      const tableHeaders = ["Nom et Prenom", "Payment (Fbu)", "Bank Account"];
      const tableData = employeePayments.map(payment => [
        payment.name,
        formatNumber(payment.payment),
        payment.Bank_account
      ]);
  
      doc.autoTable({
        startY: currentY,
        head: [tableHeaders],
        body: tableData,
        theme: "grid",
        styles: {
          fontSize: 10,
          cellPadding: 3,
          overflow: "linebreak",
          halign: "left"
        },
        headStyles: {
          fillColor: [41, 128, 185],
          textColor: 255,
          fontStyle: "bold"
        },
        columnStyles: {
          0: { cellWidth: 70 },
          1: { cellWidth: 40 },
          2: { cellWidth: 70 }
        },
        margin: { left: margin, right: margin },
        didDrawPage: (data) => addHeader()
      });
  
      // Footer Content
      let finalY = doc.lastAutoTable.finalY + 15;
  
      // Check page space
      if (finalY > pageHeight - 50) {
        doc.addPage();
        addHeader();
        finalY = 40;
      }
  
      // Closing text
      doc.setFontSize(11);
      doc.text(
        "Je vous prie de croire, Monsieur le Directeur, l'expression de ma considération distinguée.",
        margin,
        finalY
      );
  
      // Signature Section
      const signatureY = finalY + 20;
      doc.setFont("helvetica", "bold");
      doc.text("Fait par: D.G, Mr Mike B. MUHIMUZI", margin, signatureY);
      
      // Signature line
      doc.setDrawColor(0);
      //doc.line(margin, signatureY + 8, margin + 80, signatureY + 8);
      
      // Seal box
      // const sealY = signatureY + 20;
      // doc.setDrawColor(200);
      // doc.rect(margin, sealY, 45, 45);
      // doc.setFontSize(8);
      // doc.text("Espace pour cachet", margin + 5, sealY + 50);
  
      // Generate PDF
      const pdfBlob = doc.output("blob");
      const pdfURL = URL.createObjectURL(pdfBlob);
  
      setPdfDoc(pdfURL);
      setPdf(doc);
      setOpenPdf(true);
    } catch (err) {
      console.error("Error generating PDF:", err);
      toast.error("An error occurred while generating the PDF");
    }
  };
  
  // Function to confirm payment by sending a password
  const handleConfirmPayment = async () => {
    try {
      const response = await axios.post(`${server}/loan/payment/confirm`);

      if (response.data.success) {
        toast.success("Payment confirmed.");
        pdf.save("employee_payments.pdf");
        window.location.reload(true);
      } else {
        toast.error("Error confirming payment.");
      }
    } catch (error) {
      console.error("Error confirming payment:", error);
      toast.error("Invalid password or error occurred while confirming payment.");
    }
  };

  // Add function to handle additional hours updates

  const handleAdditionalHoursChange = async (id, newValue) => {
    try {
      // Update the state directly to reflect the change immediately
      setEmployee((prevEmployees) =>
        prevEmployees.map((emp) =>
          emp._id === id ? { ...emp, additionalHours: newValue } : emp
        )
      );

      // Send the update to the server
      await axios.put(`${server}/employee/update-additional-hours/${id}`, {
        additionalHours: newValue,
      });
      toast.success("Heures supplémentaires mises à jour avec succès");
    } catch (error) {
      toast.error("Erreur lors de la mise à jour des heures supplémentaires");
      console.error(error);
    }
  };

  const handleRowUpdate = async (newRow, oldRow) => {
    if (newRow.additionalHours !== oldRow.additionalHours) {
      await handleAdditionalHoursChange(newRow.id, newRow.additionalHours);
    }
    return newRow;
  };



  const columns = [
    {
      field: "avatar",
      headerName: "Nom et Prénom",
      width: 130,
      renderCell: (params) => (
        <div className="userListUser">
          {/* Display profile picture if available, otherwise show default image */}
          {params.row.avatar ? (
            <img
              className="userListImg"
              src={params.row.avatar.url}
              alt={params.row.name}
            />
          ) : (
            <PersonIcon className="userListImg" />
          )}
          {params.row.name}
        </div>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Contact",
      flex: 1,
    },
    {
      field: "role",
      headerName: "role",
      flex: 1,
    },
    {
      field: "address",
      headerName: "adresse",
      flex: 1,
    },
    {
      field: "cni",
      headerName: "Numero de CNI",
      flex: 1,
    },
    {
      field: "bank_account",
      headerName: "compte bancaire",
      flex: 1,
    },
    {
      field: "salary",
      headerName: "salaire (Fbu)",
      flex: 1,
    },
    {
      field: "additionalHours",
      headerName: "Heures supplémentaires",
      flex: 1,
      editable: true,
      type: 'number',
      renderCell: (params) => (
        <TextField
          type="number"
          value={params?.value}
          onChange={(e) => {
            const newValue = Math.max(0, parseInt(e.target.value) || 0);
            handleAdditionalHoursChange(params.row.id, newValue);
          }}
          inputProps={{ min: 0 }}
          size="small"
        />
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/EmployésEdit/" + params.row.id}>
              <button className="userListEdit">Modifié</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() =>
                setUserId(params.row.id) || setOpenDelete(!openDelete)
              }
            />
          </>
        );
      },
    },
    {
      field: "History",
      headerName: "History",
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/PaymentRecord/" + params.row.id}>
              <button className="userListEdit">History</button>
            </Link>
          </>
        );
      },
    }, {
      field: "contractual",
      headerName: "Type de contrat",
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            backgroundColor: params.value ? theme.palette.secondary.main : theme.palette.grey[500],
            padding: "5px 10px",
            borderRadius: "4px",
            color: "black",
          }}
        >
          {params.value ? "Contractuel" : "Non contractuel"}
        </Box>
      ),
    },
  ];

  const filteredEmployees = employee.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase()) ||
    item.role.toLowerCase().includes(searchText.toLowerCase())
  );

  const row = [];
  filteredEmployees &&
    filteredEmployees.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        cni: item.cni,
        phoneNumber: item.phoneNumber,
        role: item.role,
        address: item.address,
        bank_account: item.bank_account,
        salary: formatNumber(item.salary),
        additionalHours: item.additionalHours || 0,
        avatar: item.avatar,
        contractual: item.contractual,
      });
    });



  return (
    <Box m="20px">
      <Header title="Les employés" subtitle="Liste des employés" />
      <br />
      <FlexBetween>
        <Link to="/conge">
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "6px" }}
          >
            <HolidayVillageTwoTone /> les Congés
          </Button>
        </Link>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => navigate("/bank-overdrafts")}
          sx={{ marginBottom: 2 }}
        >
          Voir tous les découverts bancaires
        </Button>
      </FlexBetween>
      <br />
      <TextField
        variant="standard"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Rechercher un employé..."
        sx={{
          width: "300px",
          mb: 2,
          "& .MuiInputBase-root": {
            color: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary[500],
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: theme.palette.secondary[100] }} />
            </InputAdornment>
          ),
        }}
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: DataGridEmployeToolbar }}
          componentsProps={{
            toolbar: {
              openPopup,
              setOpenPopup,
              open,
              setOpen,
            }
          }}
          onRowUpdate={handleRowUpdate}
          processRowUpdate={handleRowUpdate}
        />
      </Box>
      <Popup title="Employé" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <FormEmployee setOpenPopup={setOpenPopup} />
      </Popup>
      {/* confirmation de payment */}
      <PopupConfirm
        title="Entre le mot de passe pour effectuer le Payment "
        open={open}
        setOpen={setOpen}
      >
        <Grid container spacing={2} justifyContent="center">
          {password.map((value, index) => (
            <Grid item key={index}>
              <TextField
                id={`otp-input-${index}`}
                type={showPassword ? "text" : "password"}
                inputProps={{ maxLength: 1 }}
                value={value}
                onChange={(e) => handleChange(index, e.target.value)}
                variant="outlined"
                style={{
                  width: '40px',
                  height: '50px',
                  fontSize: '24px',
                  textAlign: 'center',
                  margin: '0 5px',
                  boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                }}
              />
            </Grid>
          ))}
        </Grid>
        <FlexBetween style={{ marginTop: '20px' }}>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: '1px' }}
            onClick={handleSubmit}
          >
            <CheckBox />
            Soumettre
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: '1px', marginLeft: '10px' }}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: '1px', marginLeft: '10px' }}
            onClick={() => setOpen(false)}
          >
            <DeleteOutlined />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
      {/* telechargement d'un pdf */}
      <PopupPreview
        title="Preview Payment PDF"
        openPdf={openPdf}
        setOpenPdf={setOpenPdf}
        pdfDoc={pdfDoc}
        pdf={pdf}
        handleConfirmPayment={handleConfirmPayment}
      />


      {/* confirme la suppression */}
      <PopupConfirm
        title="Voulez-vous supprimer l'employee "
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete) || handleDelete(userId)}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete)}
          >
            <DeleteOutlined />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>

    </Box>

  );
};

export default Employees;
