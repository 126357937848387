import React, { useEffect, useState } from "react";
import { Box, useTheme, Button, InputAdornment, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import axios from "axios";
import { server } from "server";
import { Link } from "react-router-dom";

import FormRetrait from "scenes/formretrait";
import PopupVente from "components/PopupVente";
import DataGridRetraitToolbarDetails from "components/DataGridRetraitToolbarDetails";
import {
  AddBox,
  CancelPresentationSharp,
  CheckBox,
  DeleteOutline,
  ListAltOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search"
import PopupConfirm from "components/PopupConfirm";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const Retrait = () => {
  const theme = useTheme();

  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  // values to be sent to the backend
  const [openPopup, setOpenPopup] = useState(false);
  const [product, setProduct] = useState([]);
  const [userId1, setUserId1] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    axios.get(`${server}/product/get-all-products`).then((res) => {
      setProduct(res.data.allproducts);
    });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${server}/product/delete-product/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Creé le",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 1,
    },

    //CHANGE HERE, SO ONLY THE SUPERADMIN CAN SEE PRICES

    // {
    //   field: "price_vente",
    //   headerName: "Prix d'achat",
    //   flex: 1,
    // },
    {
      field: "stock",
      headerName: "Quantité en Stock",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/productEdit/" + params.row.id}>
              <button className="userListEdit">Edit</button>
            </Link>
            {data?.role === "superadmin" ? (
              <DeleteOutline
                className="userListDelete"
                onClick={() =>
                  setUserId1(params.row.id) || setOpenDelete(!openDelete)
                }
              />
            ) : (
              ""
            )}

            <Link to={"/ProductPriceEdit/" + params.row.id}>
              <button className="userListEdit">Ajouter</button>
            </Link>
          </>
        );
      },
    },
  ];
  const row = [];

  product &&
    product.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        desc: item.desc,
        price_vente: item.price_achat,
        stock: item.stock,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="Stocks"
        subtitle="Liste des Produits retires dans le stock"
      />
      <br />
      <Link to="/pendingproduct">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
        >
          <ListAltOutlined />
          Les Produits
        </Button>
      </Link>
      <br />
      <TextField
        variant="standard"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Rechercher un produit..."
        sx={{
          width: "300px",
          mb: 2,
          "& .MuiInputBase-root": {
            color: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary[500],
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: theme.palette.secondary[100] }} />
            </InputAdornment>
          ),
        }}
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: DataGridRetraitToolbarDetails }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <PopupVente
        title="Formulaire des retraits"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormRetrait setOpenPopup={setOpenPopup} />
      </PopupVente>

      <PopupConfirm
        title="Voulez-vous supprimer le produit "
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete) || handleDelete(userId)}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete)}
          >
            <CancelPresentationSharp />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
    </Box>
  );
};

export default Retrait;
