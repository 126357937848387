import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "server";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Box, Button, Typography, Divider } from "@mui/material";
import { nif } from "server";
import { rccm } from "server";
import { ArrowLeftSharp } from "@mui/icons-material";

const Print = () => {
  const { id } = useParams();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const pdfRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${server}/loan/get-payment/${id}`);
        setPaymentDetails(response.data.paymentHistory);
        setError(null);
      } catch (error) {
        console.error("Error fetching payment details:", error);
        setError("Failed to load payment details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const handleDownloadPDF = () => {
    const input = pdfRef.current;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save("Historique_de_paiement.pdf");
    });
  };

  const calculateTotalPayment = () => {
    if (!paymentDetails?.employeePayments) return 0;
    return paymentDetails.employeePayments.reduce((total, item) => {
      return total + (Number(item.payment) || 0);
    }, 0);
  };

  const calculateTotalBankOverdraft = () => {
    if (!paymentDetails?.employeePayments) return 0;
    return paymentDetails.employeePayments.reduce((total, item) => {
      return total + (Number(item.deductions?.bankOverdraft?.amount) || 0);
    }, 0);
  };

  if (loading) {
    return <Box m="20px">Loading payment details...</Box>;
  }

  if (error) {
    return <Box m="20px" color="error.main">{error}</Box>;
  }

  if (!paymentDetails) {
    return <Box m="20px">No payment details found.</Box>;
  }

  return (
    <div className="invoice-wrapper" id="print-area">
      <Box m="20px">
        <Link to="/history">
          <Button
            color="secondary"
            variant="contained"
            startIcon={<ArrowLeftSharp />}
            sx={{ mb: 3 }}
          >
            Retour
          </Button>
        </Link>
      </Box>
      <br />
      <div className="invoice" ref={pdfRef}>
        <div className="invoice-container">
          <div className="invoice-head">
            <div className="invoice-head-top">
              <div className="invoice-head-top-left text-start">
                <img src={Logo} alt="Logo" />
              </div>
              <div className="invoice-head-top-right text-end">
                <h3>Historique de paiement</h3>
              </div>
            </div>
            <div className="hr"></div>
            <div className="invoice-head-middle">
              <div className="invoice-head-middle-left text-start">
                <p>
                  <span className="text-bold">Date</span>:{" "}
                  {new Date(paymentDetails.createdAt).toLocaleDateString()}
                </p>
                <p>
                  <span className="text-bold">Numéro de référence</span>:{" "}
                  {paymentDetails.referenceNumber || 'N/A'}
                </p>
              </div>
            </div>
            <div className="hr"></div>
            <div className="invoice-head-bottom">
              <div className="invoice-head-bottom-left">
                <ul>
                  <li className="text-bold">Bismos High Tech:</li>
                  <li>NIF: {nif}, RC:{rccm}</li>
                  <li>Avenue: KIGARAMA, N0: 21</li>
                  <li>centre Fiscal:Ressort Nord </li>
                  <li>Secteur d'activité: COM. General Forme Juridique: Personne Morale</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="overflow-view">
            <div className="invoice-body">
              <table>
                <thead>
                  <tr>
                    <th className="text-bold">Nom et Prenom</th>
                    <th className="text-bold">Paiement</th>
                    <th className="text-bold">Crédité</th>
                    <th className="text-bold">Crédit Restant</th>
                    <th className="text-bold">Mois à payer</th>
                    <th className="text-bold">Découvert bancaire</th>
                    <th className="text-bold">Découvert Restant</th>
                    <th className="text-bold">Mois Restants</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentDetails.employeePayments?.map((lineItem, index) => (
                    <tr key={index}>
                      <td>{lineItem.name || '-'}</td>
                      <td>{lineItem.payment || '-'}</td>
                      <td>{lineItem.hasLoan ? "Oui" : "Non"}</td>
                      <td>{lineItem.remainedCredits || '-'}</td>
                      <td>{lineItem.remainedMonths || '-'}</td>
                      <td>{lineItem.deductions?.bankOverdraft?.amount || '-'}</td>
                      <td>{lineItem.deductions?.bankOverdraft?.remainingAmount || '-'}</td>
                      <td>{lineItem.deductions?.bankOverdraft?.monthsLeft || '-'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Box sx={{ mt: 3, p: 2, bgcolor: 'grey.100' }}>
            <Typography variant="h6" gutterBottom>
              Résumé des paiements
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography>Total des paiements:</Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {calculateTotalPayment().toLocaleString()} FBU
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography>Total découvert bancaire:</Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {calculateTotalBankOverdraft().toLocaleString()} FBU
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Nombre d'employés:</Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                {paymentDetails.employeePayments?.length || 0}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ my: 3 }} />
          <div className="invoice-foot">
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                À retenir
              </Typography>
              <ul style={{ margin: 0, paddingLeft: '20px' }}>
                <li>Ce document est généré automatiquement et ne nécessite pas de signature physique</li>
                <li>Les montants sont en FBU (Franc Burundias)</li>
                <li>Les crédits restants sont calculés après déduction des paiements effectués</li>
                <li>Les découverts bancaires sont déduits automatiquement des paiements</li>
                <li>Pour toute question, veuillez contacter le service comptabilité</li>
              </ul>
            </Box>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Typography variant="body2" color="text.secondary">
                Document généré le {new Date().toLocaleString()}
              </Typography>
            </Box>
          </div>
        </div>
      </div>
      <div className="invoice-btns">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
          onClick={handleDownloadPDF}
        >
          Télécharger
        </Button>
      </div>
    </div>
  );
};

export default Print;
